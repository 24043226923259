import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import firebase from 'firebase';

import servicePhoto from './paddle.svg'
import ettuBackgroup from './ETTUWomensScoreboard.png'



export default class ETTUStream2022 extends React.Component {

    state = {
        AHasTimeOut: false,

        AServes: null,
        ATimeOutActive: null,
        AisServing: null,
        BTimeOutActive: null,
        ColorA: "",
        ColorB: "",
        PlayerA2FirstName: "",
        PlayerA2LastName: "",
        PlayerAFirstName: "",
        PlayerALastName: "",
        PlayerB2FirstName: "",
        PlayerB2LastName: "",
        PlayerBFirstName: "",
        PlayerBLastName: "",
        TeamBName: "",
        TournamentName: "",
        active: null,
        bestOf5or7: "",
        competitionRound: "",
        editing: false,
        isDoubles: null,
        isInternationalTournament: null,
        isTeamTournament: null,
        playerAMatchScore: 0,
        playerAScore: 0,
        playerBMatchScore: 0,
        playerBScore: 0,
        TeamAWins: 0,
        TeamBWins: 0,
        status: "inactive",
        switch: false,
        tableName: "Table 1",
        tournamentName: "",
        magnifier: 1,
        styles: {

            showJerseyColors: true,
            transformSkewDegrees: 0,
            componentSpacing: 0,
            teamNameBackgroundColor: "navy",
            //teamBBackgroundColor: "navy",
            playerNameBackgroundColor: "navy",
            // playerBNameBackgroundColor: "navy",
            matchScoreBackgroundColor: "navy",
            gameScoreBackgroundColor: "navy",
            teamScoreBackgroundColor: "navy",

            fontSize: 32, //Default 32

            teamNameWidthDefault: 200,
            playerNameWidthDefault: 300,
            imgWidthDefault: 120,

            teamNameWidth: 200, //200
            playerNameWidth: 300, //300
            teamScoreWidth: 50, //50
            matchScoreWidth: 50, //50
            gameScoreWidth: 50,//50

            teamScoreColor: "white", //white
            matchScoreColor: "white",//white
            gameScoreColor: "white",//white
            teamNameColor: "white",//white
            playerNameColor: "white",//white
            roundedInternalEdges: 0,// 0
            borderWidth: 0, //0
            borderColor: "white", //white
            borderStyle: "solid", //solid
            serviceIconColor: "navy",//"#03006D"
            serviceIconWidth: 40, //40
            serviceIconHeight: 50,//50

            jerseyColorWidth: 20, //20
            universalHeight: 60, // 60

            hasImg: true,
            imgWidth: 120,
            imgURL: "https://livettscoreboard.com/wp-content/uploads/2020/08/512.png",
            textFont:{
                css:"",
                name:""
            }

        }

    }

//    state= {
//         "AHasTimeOut": true,
//         "AServes": true,
//         "ATimeOutActive": false,
//         "AisServing": false,
//         "BTimeOutActive": false,
//         "ColorA": "dodgerblue",
//         "ColorB": "red",
//         "PlayerA2FirstName": "",
//         "PlayerA2LastName": "",
//         "PlayerAFirstName": "Dimitri",
//         "PlayerALastName": "Octcharov",
//         "PlayerB2FirstName": "",
//         "PlayerB2LastName": "",
//         "PlayerBFirstName": "Paul",
//         "PlayerBLastName": "Drinkhall",
//         "TeamBName": "HUN U23",
//         "TournamentName": "Superliga, 12.10.2022 Bratislava stol 2",
//         "active": false,
//         "bestOf5or7": "5",
//         "competitionRound": "League Match",
//         "editing": false,
//         "isDoubles": false,
//         "isInternationalTournament": null,
//         "isTeamTournament": true,
//         "playerAMatchScore": 0,
//         "playerAScore": 0,
//         "playerBMatchScore": 0,
//         "playerBScore": 0,
//         "TeamAWins": 4,
//         "TeamBWins": 2,
//         "status": "inactive",
//         "switch": false,
//         "tableName": "Table 1",
//         "tournamentName": "Superliga, 12.10.2022 Bratislava stol 2",
//         "magnifier": 1,
//         "styles": {
//             "borderColor": "white",
//             "borderStyle": "solid",
//             "borderWidth": 0,
//             "componentSpacing": 0,
//             "creatorID": "LxrprQmcPqhBO34Bz6CdoonSmay1",
//             "fontSize": 32,
//             "gameScoreBackgroundColor": "navy",
//             "gameScoreColor": "white",
//             "gameScoreWidth": 50,
//             "hasImg": false,
//             "hideTeamName": false,
//             "hideTeamScore": false,
//             "id": "H7G8Y8PN6E",
//             "imgURL": "https://livettscoreboard.com/wp-content/uploads/2020/08/512.png",
//             "imgWidth": 120,
//             "imgWidthDefault": 120,
//             "jerseyColorWidth": 20,
//             "matchScoreBackgroundColor": "navy",
//             "matchScoreColor": "white",
//             "matchScoreWidth": 50,
//             "playerNameBackgroundColor": "navy",
//             "playerNameColor": "white",
//             "playerNameWidth": 300,
//             "playerNameWidthDefault": 300,
//             "roundedInternalEdges": 0,
//             "serviceIconColor": "navy",
//             "serviceIconHeight": 50,
//             "serviceIconWidth": 40,
//             "showJerseyColors": true,
//             "teamNameBackgroundColor": "navy",
//             "teamNameColor": "white",
//             "teamNameWidth": 200,
//             "teamNameWidthDefault": 200,
//             "teamScoreBackgroundColor": "navy",
//             "teamScoreColor": "white",
//             "teamScoreWidth": 50,
//             "textFont": {
//                 "css": "arial",
//                 "name": "Arial"
//             },
//             "transformSkewDegrees": 0,
//             "universalHeight": 60
//         },
//         "TeamAName": "CZECH U 23",
//         "PlayerA2HasImage": false,
//         "PlayerA2ImageURL": "",
//         "PlayerA2Imported": false,
//         "PlayerAHasImage": false,
//         "PlayerAImageURL": "",
//         "PlayerAImported": false,
//         "PlayerB2HasImage": false,
//         "PlayerB2ImageURL": "",
//         "PlayerB2Imported": false,
//         "PlayerBHasImage": false,
//         "PlayerBImageURL": "",
//         "PlayerBImported": false,
//         "BHasTimeOut": true,
//         "currentUmpire": "",
//         "gameid": "562QHWKOA5RUIOZBMMH77YGH1PQQN7",
//         "isLeagueGame": true,
//         "magnify": 1,
//         "scorboardDirectionAway": true,
//         "scoreboardTheme": "default",
//         "selectedServer": false,
//         "showColor": false,
//         "showFlags": false,
//         "tableWasEdited": false
//     }


    magnifySize(magnifier, addOrMinus) {

        var newValues = {
            teamNameWidth: this.state.styles.teamNameWidth,
            playerNameWidth: this.state.styles.playerNameWidth,
            teamScoreWidth: this.state.styles.teamScoreWidth,
            matchScoreWidth: this.state.styles.matchScoreWidth,
            gameScoreWidth: this.state.styles.gameScoreWidth,
            fontSize: this.state.styles.fontSize, //Default 32
            universalHeight: this.state.styles.universalHeight,
            jerseyColorWidth: this.state.styles.jerseyColorWidth,
            serviceIconWidth: this.state.styles.serviceIconWidth,
            serviceIconHeight: this.state.styles.serviceIconHeight,
            imgWidth: this.state.styles.imgWidth
        }

        for (const key in newValues) {
            if (addOrMinus == "add") {
                newValues[key] = newValues[key] + newValues[key] * 0.1
            }
            if (addOrMinus == "minus") {
                newValues[key] = newValues[key] - newValues[key] * 0.1
            }



        }

        var newStyle = {
            ...this.state.styles,
            ...newValues

            /* teamNameWidth: this.state.styles.teamNameWidthDefault+(),
                 playerNameWidth: this.state.styles.playerNameWidthDefault*magnifier,
                 teamScoreWidth: 50*magnifier,
                 matchScoreWidth: 50*magnifier,
                 gameScoreWidth: 50*magnifier,
                 fontSize: 32*magnifier, //Default 32
                 universalHeight: 60*magnifier,
                 jerseyColorWidth: 20*magnifier,
                 serviceIconWidth: 40*magnifier,
                 serviceIconHeight: 50*magnifier,
                 imgWidth: this.state.styles.imgWidthDefault* magnifier
     */
        }

        this.setState({ styles: newStyle })

    }


    serviceIcon = (props) => {
        return (
            <svg width={props.width} height={props.height} viewBox="0 0 512 512" fill="none" class='paddle' xmlns="http://www.w3.org/2000/svg">
                <path d="M446.877 65.2196C508.079 126.519 524.017 215.964 494.764 291.97C430.656 251.027 347.029 266.076 300.291 326.149L93.3407 119.198L147.223 65.2194L147.224 65.2188C229.952 -17.7063 364.148 -17.7063 446.876 65.2188L446.877 65.2196ZM278.431 467.368C255.143 463.846 232.823 453.481 214.921 435.579L179.221 399.879L176.94 397.598L174.83 400.038L85.539 503.328C85.5381 503.329 85.5372 503.33 85.5364 503.331C79.1642 510.635 67.9878 511.03 61.2344 504.192L61.2289 504.186L61.2233 504.181L7.8233 450.681L7.82131 450.679C0.957737 443.815 1.37396 432.63 8.66749 426.265C8.66892 426.263 8.67035 426.262 8.67178 426.261L111.666 336.866L114.094 334.758L111.824 332.482L77.3244 297.882L77.324 297.881C40.2766 260.739 34.15 204.498 58.5377 160.779L274.929 377.072C271.441 389.42 269.003 402.352 269.1 415.909C269.1 415.913 269.1 415.917 269.1 415.922L278.431 467.368ZM323 416C323 364.657 364.657 323 416 323C467.343 323 509 364.657 509 416C509 467.343 467.343 509 416 509C364.657 509 323 467.343 323 416Z" fill={props.fill} stroke="white" stroke-width="6" />
            </svg>

        )
    }



    componentWillMount() {
        console.log(window.location)
        const mySearchParams = new URLSearchParams(window.location.search)
        let delay = 0
        for (const [key, value] of mySearchParams) {
            if (key =="delay"){
                delay= parseInt(value)
            }
            
        }
        //console.log(this.props.match.params)
        if (this.props.match.params.staticurl) {
            firebase.database().ref("staticurl").orderByChild("staticurl").equalTo(this.props.match.params.staticurl).on("value", (val) => {
                //console.log(val.val())
                if (val.val()) {
                    if (val.val()[Object.keys(val.val())[0]].tournamentID) {
                        var tournamentID = val.val()[Object.keys(val.val())[0]].tournamentID
                        firebase.database().ref("tournaments").child(tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
                            this.setState(val.val())

                            //console.log(val.val())
                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("TeamAWins").on("value", (wins) => {
                            if (typeof wins.val() != "undefined") {

                                this.setState({ TeamAWins: wins.val() })
                            }
                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("TeamBWins").on("value", (wins) => {
                            if (typeof wins.val() != "undefined") {
                                this.setState({ TeamBWins: wins.val() })
                            }
                        })
                        firebase.database().ref("tournaments").child(tournamentID).once("value", (val) => {
                            this.setState({ TournamentName: val.val().TournamentName })
                            if (val.val().liveStreamScoreboardID) {
                                firebase.database().ref("livestream").child(val.val().liveStreamScoreboardID).on("value", (style) => {
                                    this.setState({ styles: style.val() })
                                })
                            }

                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("magnify").on("value", (magnification) => {
                            //console.log(magnification.val())
                            this.magnifySize(magnification.val().magnify, magnification.val().magDirection)
                        }
                        )
                    }
                    else {
                        this.setState({ PlayerAFirstName: "Assign a Tournament", })
                    }
                }
                else {
                    this.setState({ PlayerAFirstName: "Unassigned URL", })
                }
            })



        }
        else {

            var neededFields = ["AServes",
                "AisServing",
                "ColorA",
                "ColorB",
                "PlayerA2FirstName",
                "PlayerA2LastName",
                "PlayerAFirstName",
                "PlayerALastName",
                "PlayerB2FirstName",
                "PlayerB2LastName",
                "PlayerBFirstName",
                "PlayerBLastName",
                "editing",
                "isDoubles",
                "isTeamTournament",
                "playerAMatchScore",
                "playerAScore",
                'playerBMatchScore',
                "playerBScore",
                "PlayerAHasImage",
                "PlayerAImported",
                "PlayerAImageURL",
                "PlayerA2HasImage",
                "PlayerA2Imported",
                "PlayerA2ImageURL",
                "PlayerBHasImage",
                "PlayerBImported",
                "PlayerBImageURL",
                "PlayerB2HasImage",
                "PlayerB2Imported",
                "PlayerB2ImageURL",

            ]
            let delayableFields = ["playerBScore","playerAScore","AisServing"]
            neededFields.forEach((field, index) => {
                firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).child(field).on("value", (val) => {
                    if(delayableFields.includes(field)){
                        //console.log("Update delayed.")
                        setTimeout(()=>{
                            this.setState({ [field]: val.val() })  
                           // console.log("Update done.")
                        }, delay*1000)
                    }
                    else{
                      this.setState({ [field]: val.val() })  
                    }
                    
                })
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).once("value", (val) => {

                var tableData = val.val()
                if (tableData["isLeagueGame"]) {
                    delete tableData["TeamAName"]
                    delete tableData["TeamBName"]
                }
                else {
                    var teamNameFields = [
                        "TeamAName",
                        "TeamBName",
                    ]

                    teamNameFields.forEach((field, index) => {
                        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).child(field).on("value", (val) => {
                            this.setState({ [field]: val.val() })
                        })
                    })

                }

                this.setState(tableData)

                //console.log(val.val())
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAWins").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {

                    this.setState({ TeamAWins: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBWins").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {
                    this.setState({ TeamBWins: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAName").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {

                    this.setState({ TeamAName: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBName").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {
                    this.setState({ TeamBName: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).once("value", (val) => {
                this.setState({ TournamentName: val.val().TournamentName })
                firebase.database().ref("livestream").child(val.val().liveStreamScoreboardID).on("value", (style) => {
                    this.setState({ styles: style.val() })
                })
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("magnify").on("value", (magnification) => {
                //console.log(magnification.val())
                this.magnifySize(magnification.val().magnify, magnification.val().magDirection)
            }
            )
        }


    }

    componentDidMount() {


    }




    render() {
        
        
        //console.log(this.state)
        return (
            <div style={{display:"flex", flexDirection:"row", backgroundImage:"url("+ettuBackgroup+")", backgroundRepeat:"no-repeat", height:400}} >

                {
                    /*
<Paper  style={{float:"left", width:this.state.isTeamTournament? 700 :475, height:50,backgroundColor:"navy", margin: this.state.styles.componentSpacing, marginLeft:20, justifyContent:"center", alignItems:"center", transform:"skew(-20deg)"}}>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.TournamentName}</Typography>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.tableName} - {this.state.competitionRound}</Typography>
                    </Paper>
 
                    */
                }
                            <div style={{position:"absolute",  display:"flex", top: 22, left:382,  width:27, height:27, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: 16 ,fontWeight:"bold", color: this.state.styles.teamScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial", }}>{this.state.TeamAWins}</Typography>

                            </div>
                            <div style={{position:"absolute", display:"flex", top: 22, left:420,  width:27, height:27, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: 16 , fontWeight:"bold", color: this.state.styles.teamScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial", }}>{this.state.TeamBWins}</Typography>
                            </div>
                            <div style={{position:"absolute", display:"flex", top: 22, left:216,  width:163, height:27, justifyContent:"flex-end", alignItems:"center"}}>
                            <Typography noWrap style={{ fontSize: 16 , fontWeight:"bold", color: "#126FA8", fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"   }}>{this.state.TeamAName}</Typography>
                            </div>
                            <div style={{position:"absolute", display:"flex", top: 22, left:452,  width:163, height:27, justifyContent:"flex-start", alignItems:"center"}}>
                            <Typography noWrap style={{ fontSize: 16 , fontWeight:"bold", color: "#126FA8", fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"   }}>{this.state.TeamBName}</Typography>
                            </div>
                            <div style={{position:"absolute", display:"flex", top: 49, left:170,  width:11, height:43, justifyContent:"center", alignItems:"center", backgroundColor:this.state.ColorA}}>
                            </div>
                            <div style={{position:"absolute", display:"flex", top: 94, left:170,  width:11, height:43, justifyContent:"center", alignItems:"center", backgroundColor:this.state.ColorB}}>
                            </div>

                            <div style={{position:"absolute", display:"flex", top: 50, left:235,  width:380, height:40, justifyContent:"flex-start", alignItems:"center"}}>
                            <Typography noWrap style={{ fontSize: this.state.styles.fontSize, fontWeight:"bold", color: this.state.styles.playerNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.editing ? "--" : this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 && this.state.PlayerA2LastName.length >= 1) ? this.state.PlayerALastName + "/" + this.state.PlayerA2LastName : this.state.PlayerAFirstName + "/" + this.state.PlayerA2FirstName : this.state.PlayerAFirstName + " " + this.state.PlayerALastName}</Typography>

                            </div>

                            <div style={{position:"absolute", display:"flex", top: 94, left:235,  width:380, height:40, justifyContent:"flex-start", alignItems:"center"}}>
                            <Typography noWrap style={{ fontSize: this.state.styles.fontSize, fontWeight:"bold", color: this.state.styles.playerNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.editing ? "--" : this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 && this.state.PlayerB2LastName.length >= 1) ? this.state.PlayerBLastName + "/" + this.state.PlayerB2LastName : this.state.PlayerBFirstName + "/" + this.state.PlayerB2FirstName : this.state.PlayerBFirstName + " " + this.state.PlayerBLastName}</Typography>

                            </div>
                            <div style={{position:"absolute", display:"flex", top:50, left:615,  width:50, height:40, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: this.state.styles.fontSize, fontWeight:"bold", color: this.state.styles.matchScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerAMatchScore}</Typography>

                            </div>
                            <div style={{position:"absolute", display:"flex", top:94, left:615,  width:50, height:40, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: this.state.styles.fontSize, fontWeight:"bold", color: this.state.styles.matchScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerBMatchScore}</Typography>

                            </div>

                            <div style={{position:"absolute", display:"flex", top:50, left:667,  width:50, height:40, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: this.state.styles.fontSize,fontWeight:"bold", color: "#126FA8", fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerAScore}</Typography>

                            </div>
                            <div style={{position:"absolute", display:"flex", top:94, left:667,  width:50, height:40, justifyContent:"center", alignItems:"center"}}>
                            <Typography style={{ fontSize: this.state.styles.fontSize,fontWeight:"bold", color: "#126FA8", fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerBScore}</Typography>

                            </div>


           
                {/* <div style={{position:"absolute", top:400}}>
                
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team A SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }

                    {
                        // this.state.isLeagueGame && !this.state.styles.hideTeamScore ? 
                      
                        //     : null
                    }
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team A NAME ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    {
                        // this.state.isTeamTournament && !this.state.styles.hideTeamName  ? 
                        //     <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"   }}>{this.state.editing ? "--" : this.state.TeamAName}</Typography>
                        // : null
                    }
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// PLAYER A NAME ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    
                        <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.playerNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.editing ? "--" : this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 && this.state.PlayerA2LastName.length >= 1) ? this.state.PlayerALastName + "/" + this.state.PlayerA2LastName : this.state.PlayerAFirstName + "/" + this.state.PlayerA2FirstName : this.state.PlayerAFirstName + " " + this.state.PlayerALastName}</Typography>
                   

                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Player A MATCH SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    
                        <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.matchScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerAMatchScore}</Typography>
                    
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Player A SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                   
                        <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.gameScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerAScore}</Typography>
                 


                    {
                        this.state.AisServing  ?
                            <div style={{ float: "left", width: 80, height: this.state.styles.universalHeight, display: "inline-flex", alignItems: "center", verticalAlign: "middle", margin: this.state.styles.componentSpacing, }}>
                                {
                                    //<img height={50} width={40} style={{fill:"red"}} src={servicePhoto} />
                                }
                                <this.serviceIcon fill={this.state.styles.serviceIconColor} width={this.state.styles.serviceIconWidth} height={this.state.styles.serviceIconHeight} />
                            </div>

                            : null
                    }


                

                {
                    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    ////////////////////////////// PLAYER B STARTS HERE ////////////////////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                }

                <div style={{ display: "table" }}>
               



                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team B SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    {
                        this.state.isLeagueGame && !this.state.styles.hideTeamScore ? 
                            <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.TeamBWins}</Typography>
                      
                            : null
                    }
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team B NAME ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }

                    {this.state.isTeamTournament && !this.state.styles.hideTeamName  ? 
                        <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.editing ? "--" : this.state.TeamBName}</Typography>
                    : null
                    }

                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// PLAYER B NAME(S) ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }

                   
                        <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.playerNameColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.editing ? "--" : this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 && this.state.PlayerB2LastName.length >= 1) ? this.state.PlayerBLastName + "/" + this.state.PlayerB2LastName : this.state.PlayerBFirstName + "/" + this.state.PlayerB2FirstName : this.state.PlayerBFirstName + " " + this.state.PlayerBLastName}</Typography>
                  

                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// PLAYER B MATCH SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    
                        <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.matchScoreColor , fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial" }}>{this.state.playerBMatchScore}</Typography>
                   
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// PLAYER B SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                   
                        <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.gameScoreColor, fontFamily: this.state.styles.textFont? this.state.styles.textFont.css :"arial"  }}>{this.state.playerBScore}</Typography>
                  

                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// PLAYER B SERVICE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    {
                        !this.state.AisServing  ?
                            <div style={{ float: "left", width: 80, height: this.state.styles.universalHeight, display: "inline-flex", alignItems: "center", verticalAlign: "middle", margin: this.state.styles.componentSpacing, }}>
                                <this.serviceIcon fill={this.state.styles.serviceIconColor} width={this.state.styles.serviceIconWidth} height={this.state.styles.serviceIconHeight} />
                                {
                                    //<img height={50} width={40} style={{fill:"red"}} src={servicePhoto} />
                                }

                            </div>
                            : null
                    }


                </div>
</div> */}


            </div>



        )
    }
}