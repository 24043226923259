import React from 'react';
import { Typography, Divider } from '@material-ui/core';

/**
 * 
 * @param {*} props 
 * @param {string ("A"| "B")} props.playerLetter
 * @param {number} props.styles.player_row_height
 * @param {number} props.styles.player_row_color_height
 * @param {number} props.styles.player_row_color_width
 * @param {number} props.styles.player_row_team_name_width
 * @param {number} props.styles.player_row_score_width
 * @param {number} props.styles.name_font_size
 * @param {number} props.TeamBWins
 * @param {number} props.TeamAWins
 * @param {number} props.playerAScore
 * @param {number} props.playerAMatchScore
 * @param {number} props.playerBScore
 * @param {number} props.playerBMatchScore
 * 
 * @param {string} props.TeamAName
 * @param {string} props.TeamBName
 * 
 * 
 * @param {boolean} props.isTeamTournament
 * @param {boolean} props.isDoubles
 * @param {boolean} props.isLeagueGame
 * 
 */
const PlayerRow = (props) => {
    function playerName(PlayerFirstName, PlayerLastName, Player2FirstName, Player2LastName, isDoubles) {
        if (props.isDoubles) {
            if (PlayerLastName.length < 1 || Player2LastName < 1) {
                return `${PlayerFirstName}/${Player2FirstName}`
            }
            else {
                return `${PlayerLastName}\/${Player2LastName}`
            }
        }
        else {
            return `${PlayerFirstName} ${PlayerLastName}`
        }
    }

    let TeamScore
    let TeamName
    let PlayerName
    let MatchScore
    let GameScore
    let Color
    let Serving
    if (props.playerLetter == "A") {
        if (props.editing) {
            TeamScore = props.TeamAWins
            TeamName = "--"
            PlayerName = "--"
            MatchScore = props.playerAMatchScore
            GameScore = props.playerAScore
            Color = props.ColorA
            Serving = props.AisServing
        }
        else {
            TeamScore = props.TeamAWins
            TeamName = props.TeamAName
            PlayerName = playerName(props.PlayerAFirstName, props.PlayerALastName, props.PlayerA2FirstName, props.PlayerA2LastName)
            MatchScore = props.playerAMatchScore
            GameScore = props.playerAScore
            Color = props.ColorA
            Serving = props.AisServing
        }


    }
    else if (props.playerLetter == "B") {
        if (props.editing) {
            TeamScore = props.TeamBWins
            TeamName = "--"
            PlayerName = "--"
            MatchScore = props.playerBMatchScore
            GameScore = props.playerBScore
            Color = props.ColorB
            Serving = !props.AisServing
        }
        else {
            TeamScore = props.TeamBWins
            TeamName = props.TeamBName
            PlayerName = playerName(props.PlayerBFirstName, props.PlayerBLastName, props.PlayerB2FirstName, props.PlayerB2LastName)
            MatchScore = props.playerBMatchScore
            GameScore = props.playerBScore
            Color = props.ColorB
            Serving = !props.AisServing
        }

    }






    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: props.styles.player_row_height,
            alignItems: "center",
            justifyContent:"space-around"
        }}>
            <div style={{
                backgroundColor: Color,
                height: props.styles.player_row_color_height,
                width: props.styles.player_row_color_width,
                margin: 4,
                borderRadius: 10
            }} />
            {
                props.isLeagueGame ?
                    <div style={{
                        width: props.styles.player_row_score_width,
                        justifyContent: "center",
                        alignItems: "center"
                    }} >
                        <Typography align="center" style={{
                            fontSize: props.styles.score_font_size,
                            color: props.styles.font_color,
                            fontWeight:"bold",
                            margin: 5
                        }}>{TeamScore}</Typography>
                    </div>
                    : null
            }
            {
                props.isLeagueGame ?
                <Divider orientation='vertical'></Divider>
                :null
            }
            {
                props.isTeamTournament ? 
                <div style={{
                width: props.styles.player_row_team_name_width
            }}>
                <Typography style={{
                    fontSize: props.styles.name_font_size,
                    color: props.styles.font_color,
                    margin: 5
                }}>{TeamName}</Typography>
            </div>
            :null
            }
            
            <Divider orientation='vertical'></Divider>
            <div style={{
                width: props.styles.player_row_player_name_width
            }}>
                <Typography style={{
                    fontSize: props.styles.name_font_size,
                    color: props.styles.font_color,
                    margin: 5
                }}>{PlayerName}</Typography>
            </div>
            <Divider orientation='vertical'></Divider>
            <div style={{
                width: props.styles.player_row_score_width,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Typography align="center" style={{
                    fontSize: props.styles.score_font_size,
                    color: props.styles.font_color,
                    fontWeight:"bold",
                    margin: 5
                }}>{MatchScore}</Typography>
            </div>
            <Divider orientation='vertical'></Divider>
            <div style={{
                width: props.styles.player_row_score_width,
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Typography align="center" style={{
                    fontSize: props.styles.score_font_size,
                    color: props.styles.font_color,
                    fontWeight:"bold",
                    margin: 5
                }}>{GameScore}</Typography>
            </div>
            
            {
                Serving ? 
                <svg style={{
                    overflow: "visible",
                    position: "relative",
                    width: props.styles.player_row_white_paddle_width,
                    height: props.styles.player_row_white_paddle_height,
                    fill: props.styles.font_color,
                    
                    //transform: "matrix(1,0,0,1,0,0)",

                }} viewBox="-0.075 0.025 24.255 20">
                    <path fill="rgba(255,255,255,1)" id="white_paddle" d="M 23.43134117126465 11.60492324829102 C 24.92336082458496 8.566158294677734 24.17971992492676 4.953229904174805 21.1956729888916 2.488627672195435 C 17.22168159484863 -0.7962074875831604 10.77519416809082 -0.7962074875831604 6.801201820373535 2.488627672195435 L 4.148717403411865 4.679818630218506 L 14.16658401489258 12.94073009490967 C 16.35015106201172 10.51518630981445 20.39519309997559 9.917590141296387 23.43134117126465 11.60492324829102 Z M 13.11032867431641 14.71790218353271 L 2.670903921127319 6.113272666931152 C 1.377817749977112 7.882630348205566 1.643065929412842 10.21443367004395 3.490333080291748 11.74162769317627 L 5.124453067779541 13.09305858612061 L 0.245774433016777 16.58490562438965 C -0.1615715175867081 16.87784576416016 -0.1852544248104095 17.39341926574707 0.1984085887670517 17.70979118347168 L 2.727742671966553 19.79943084716797 C 3.106669664382935 20.11580848693848 3.731898307800293 20.09628105163574 4.087142467498779 19.76037406921387 L 8.316908836364746 15.72561264038086 L 10.00786590576172 17.1200065612793 C 10.94097423553467 17.88946342468262 12.12038230895996 18.3112964630127 13.33768367767334 18.42066192626953 C 13.00138568878174 17.75666236877441 12.81665992736816 17.03017044067383 12.81665992736816 16.26852989196777 C 12.81192398071289 15.72951889038086 12.93507671356201 15.21394443511963 13.11032867431641 14.71790218353271 Z M 19.63260269165039 12.52280139923096 C 17.12221527099609 12.52280139923096 15.08548545837402 14.20232391357422 15.08548545837402 16.27243614196777 C 15.08548545837402 18.34254455566406 17.12221527099609 20.02206611633301 19.63260269165039 20.02206611633301 C 22.14299201965332 20.02206611633301 24.17971992492676 18.34254455566406 24.17971992492676 16.27243614196777 C 24.17971992492676 14.20232391357422 22.14299201965332 12.52280139923096 19.63260269165039 12.52280139923096 Z">
                    </path>
                </svg> 
                : <div style={{
                    width: props.styles.player_row_white_paddle_width,
                    height: props.styles.player_row_white_paddle_height,
                }} />
            }

        </div>
    )
}
export default PlayerRow