import React from 'react'

import {Paper} from '@material-ui/core'


function LiveStats(props) {

    const tableStyle ={
        current:{
            backgroundColor:"#7feba1"
        },
        total:{
            backgroundColor: "#90b5f0"
        }
    }

    return(
        <Paper>
            <table style={{textAlign:"center", paddingBlock:"1"}}>
                <tr>
                    <th style={{backgroundColor:"#4bc471"}} colSpan={3}>Current Game</th>
                    <th style={{backgroundColor:"#6395e6"}} colSpan={5} >Total</th>
                </tr>
                <tr>
                    
                    
                    <th style={tableStyle.current}>Points On Serve</th>
                    <th style={tableStyle.current}>% On Serve</th>
                    <th style={tableStyle.current}>Winning Streak</th>

                    <th style={tableStyle.total}>Points</th>
                    <th style={tableStyle.total}>% Points Won</th>
                    <th style={tableStyle.total}>Points On Serve</th>
                    <th style={tableStyle.total}>% On Serve</th>
                    <th style={tableStyle.total}>Longest Streak</th>
                </tr>
                <tr>
                   <td style={tableStyle.current}>{props.current.playerA.pointsWonOnServe}</td> 
                   <td style={tableStyle.current}>{Math.floor(props.current.percentages.playerA.percentPointsWonOnServe*100)+"%"}</td> 
                   <td style={tableStyle.current}>{props.current.playerA.currentStreak}</td> 
                   <td style={tableStyle.total}>{props.total.playerA.totalPointsWon}</td>
                   <td style={tableStyle.total}>{Math.floor(props.total.percentage.playerA.percentTotalPointsWon*100)+"%"}</td>
                   <td style={tableStyle.total}>{props.total.playerA.pointsWonOnServe}</td>
                   <td style={tableStyle.total}>{Math.floor(props.total.percentage.playerA.percentPointsWonOnServe*100)+"%"}</td>
                   <td style={tableStyle.total}>{props.total.playerA.longestWinningStreak}</td> 
                   
                </tr>
                <tr>

                   <td style={tableStyle.current}>{props.current.playerB.pointsWonOnServe}</td> 
                   <td style={tableStyle.current}>{Math.floor(props.current.percentages.playerB.percentPointsWonOnServe*100)+"%"}</td> 
                   <td style={tableStyle.current}>{props.current.playerB.currentStreak}</td> 
                   <td style={tableStyle.total}>{props.total.playerB.totalPointsWon}</td>
                   <td style={tableStyle.total}>{Math.floor(props.total.percentage.playerB.percentTotalPointsWon*100)+"%"}</td>
                   <td style={tableStyle.total}>{props.total.playerB.pointsWonOnServe}</td>
                   <td style={tableStyle.total}>{Math.floor(props.total.percentage.playerB.percentPointsWonOnServe*100)+"%"}</td>
                   <td style={tableStyle.total}>{props.total.playerB.longestWinningStreak}</td> 
                </tr>
                
            </table>
        </Paper>
    )
}

export default LiveStats