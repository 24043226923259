export default class ElevenVRScores {
    constructor(matchesAPIJSON) {
        this.rawJSON = matchesAPIJSON
        console.log(Object.keys(this.rawJSON))
        console.log(this.rawJSON)
        //this.included = this.rawJSON.included
        this.data = this.rawJSON.data

        console.log(this.getGameDetails(this.data))
        console.log(this.getCurrentGameScore())
        console.log(this.getCurrentScore())
        console.log(this.getPlayerNames(true))
    }

    // getGameDetails(matchData, matchIndex=0){
    //     var games = matchData[matchIndex]["attributes"]["rounds"]
    //     var gameIDs = games.map((game)=>{
    //         console.log(game)
    //         return game
    //     })
    //     return gameIDs
    // }

    getGameDetails(matchData, matchIndex = 0) {
        return matchData[matchIndex]["attributes"]["rounds"]

    }

    getServer() {
        var gameNumber = this.getGameDetails(this.data).length
        var homeStartedServing = gameNumber % 2 === 0 ? false : true
        var isInitialServerServing = this.isInitialServerServing()
        if (homeStartedServing) {
            if (isInitialServerServing) {
                return "home"
            }
            else {
                return "away"
            }
        }
        else {
            if (isInitialServerServing) {
                return "away"
            }
            else {
                return "home"
            }
        }
    }

    isInitialServerServing() {
        var currentScores = this.getCurrentScore()
        var totalScore = parseInt(currentScores["awayScore"]) + parseInt(currentScores["homeScore"])
        var halfTotalScore = totalScore / 2

        if (totalScore < 20) {
            if (Number.isInteger(halfTotalScore)) {
                if (halfTotalScore % 2 === 0) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                totalScore -= 1
                halfTotalScore = totalScore / 2
                if (halfTotalScore % 2 === 0) {
                    return true
                }
                else {
                    return false
                }
            }
        }
        else {
            //For Deuce
            if (totalScore % 2 === 0) {
                return true
            }
            else {
                return false
            }
        }

    }


    getCurrentGameScore(matchList = this.data, matchIndex = 0) {
        var homeScore = 0
        var awayScore = 0
        var GameDetails = this.getGameDetails(matchList, matchIndex)


        for (const game of GameDetails) {
            if (game["home-score"] == 11 && game["away-score"] <= 9) {
                homeScore++
            }
            else if (game["away-score"] == 11 && game["home-score"] <= 9) {
                awayScore++
            }
            else if ((game["away-score"] >= 10 && game["home-score"] >= 10) && (parseInt(game["home-score"]) - parseInt(game["away-score"]) == 2)) {
                homeScore++
            }
            else if ((game["away-score"] >= 10 && game["home-score"] >= 10) && (parseInt(game["away-score"]) - parseInt(game["home-score"]) == 2)) {
                awayScore++
            }

        }
        return {
            homeScore: homeScore,
            awayScore: awayScore
        }
    }

    getPlayerNames(includeELO = false, matchIndex = 0) {
        var awayPlayer = this.data[matchIndex]["attributes"]["players"][0]
        var homePlayer = this.data[matchIndex]["attributes"]["players"][1]

        return {
            awayPlayer: includeELO ? awayPlayer["username"] + "(" + Math.round(awayPlayer["elo"]) + ")" : awayPlayer["username"],
            homePlayer: includeELO ? homePlayer["username"] + "(" + Math.round(homePlayer["elo"]) + ")" : homePlayer["username"]
        }
    }

    getCurrentScore() {
        var currentGame = this.getGameDetails(this.data)[0]
        return {
            homeScore: currentGame["home-score"],
            awayScore: currentGame["away-score"]
        }
    }

    getConsecutiveGameWins() {
        var homeWinsScore = 0
        var awayWinsScore = 0
        var currentHomeName = this.getPlayerNames(false, 0)["homePlayer"]
        var currentAwayName = this.getPlayerNames(false, 0)["awayPlayer"]
        var recentlyPlayedGames = this.data.filter((match, index) => {
            if (index === 0) {
                return false
            }
            else {
                if ((this.getPlayerNames(false, index)["homePlayer"] == currentHomeName || this.getPlayerNames(false, index)["homePlayer"] == currentAwayName)
                    && (this.getPlayerNames(false, index)["awayPlayer"] == currentHomeName || this.getPlayerNames(false, index)["awayPlayer"] == currentAwayName)
                ) {
                    return true
                }
                else {
                    return false
                }
            }

        })
        for (let index = 0; index < recentlyPlayedGames.length; index++) {
            const consecutiveMatch = recentlyPlayedGames[index];
            var awayPlayer = consecutiveMatch["attributes"]["players"][0]["username"]
            var homePlayer = consecutiveMatch["attributes"]["players"][1]["username"]
            var gameScore = this.getCurrentGameScore(recentlyPlayedGames, index)
            if (parseInt(gameScore["homeScore"]) > parseInt(gameScore["awayScore"])) {
                if (homePlayer == currentHomeName) {
                    homeWinsScore++
                }
                else {
                    awayWinsScore++
                }
            }
            else {
                if (awayPlayer === currentAwayName) {
                    awayWinsScore++
                }
                else {
                    homeWinsScore++
                }

            }
        }
        console.log(recentlyPlayedGames)
        return {
            awayWins: awayWinsScore,
            homeWins: homeWinsScore
        }

    }



    // getGameDetails(gameIDs){
    //     var gameIDList = []
    //     if(typeof gameIDs === "string"){
    //         gameIDList.push(gameIDs)
    //     }
    //     else if(Array.isArray(gameIDs)){
    //         gameIDList = gameIDs
    //     }
    //     else{
    //         throw new Error("Game IDs must be a string or an array of game IDs")
    //     }

    //     return gameIDList.map((gameID) =>{
    //         for (let index = 0; index < this.included.length; index++) {
    //             const game = this.included[index];
    //             if(game["id"] == gameID){
    //                 return game["attributes"]
    //             }

    //         }
    //     })



    //  }



}
