import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';

import StreamScoreboard from './streamboard'
import OneTable from './onetable';
import WholeTournamentView from './wholeTournamentView'
import Main from './main'


 //Test Environment
 
//  var firebaseConfig = {
//   apiKey: "AIzaSyDjKg2BWqzGlO88tEptpZO-ZBul6yDjv-o",
//   authDomain: "livestreamscoreboard.firebaseapp.com",
//   databaseURL: "https://livestreamscoreboard.firebaseio.com",
//   projectId: "livestreamscoreboard",
//   storageBucket: "livestreamscoreboard.appspot.com",
//   messagingSenderId: "587435754887",
//   appId: "1:587435754887:web:4457669fb3eeefd54bbf22"
// };
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);

//Live Environment

var firebaseConfig = {
    apiKey: "AIzaSyBvUstSA9UG6peiA122Tk02EzhspCLsC_U",
    authDomain: "scoreboard-20871.firebaseapp.com",
    databaseURL: "https://scoreboard-20871.firebaseio.com",
    projectId: "scoreboard-20871",
    storageBucket: "scoreboard-20871.appspot.com",
    messagingSenderId: "170946083812",
    appId: "1:170946083812:web:69a746644dae06d7"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
ReactDOM.render(<Main />, document.getElementById('root'));
//ReactDOM.render(<StreamScoreboard />, document.getElementById('root'));
//ReactDOM.render(<App />, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
