import React from 'react'

const StreamV2LeagueScoreboard = (props) =>{

    return(
<div>
<meta charset="utf-8"/>
<meta http-equiv="X-UA-Compatible" content="IE=edge"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>LeagueScoreboard</title>
<style id="applicationStylesheet" type="text/css" dangerouslySetInnerHTML={{__html:`.mediaViewInfo {
		--web-view-name: LeagueScoreboard;
		--web-view-id: LeagueScoreboard;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: LeagueScoreboard;
	}
	#League_Scoreboard {
		position: absolute;
		width: 904px;
		height: 159px;
		left: 22px;
		top: 26px;
		overflow: visible;
	}
	.MainBackground_l {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		overflow: visible;
		position: absolute;
		width: 922px;
		height: 177px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PlayerB {
		position: absolute;
		width: 881px;
		height: 54px;
		left: 9px;
		top: 97px;
		overflow: visible;
	}
	#BMatchScore {
		opacity: 0.996;
		position: absolute;
		left: 704px;
		top: 1px;
		overflow: visible;
		width: 63px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.white_paddle {
		overflow: visible;
		position: absolute;
		width: 24.255px;
		height: 20px;
		left: 856.382px;
		top: 17.652px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PlayerBName {
		position: absolute;
		left: 280px;
		top: 8px;
		overflow: visible;
		width: 417px;
		height: 40px;
		line-height: 60px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}
	#PlayerBScore {
		opacity: 0.996;
		position: absolute;
		left: 786px;
		top: 1px;
		overflow: visible;
		width: 70px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.ColorB {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 40px;
		left: 0px;
		top: 8px;
	}
	#BTimeout {
		position: absolute;
		width: 26px;
		height: 21px;
		left: 855px;
		top: 17px;
		overflow: visible;
	}
	.Rectangle_4 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#T {
		position: absolute;
		left: 0px;
		top: 0px;
		overflow: visible;
		width: 26px;
		height: 21px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Timeout {
		position: absolute;
		left: 0px;
		top: 16px;
		overflow: hidden;
		width: 26px;
		height: 3px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 4px;
		color: rgba(0,0,0,1);
	}
	#TeamBName {
		position: absolute;
		left: 92px;
		top: 11px;
		overflow: visible;
		width: 173px;
		height: 40px;
		line-height: 60px;
		margin-top: -16px;
		text-align: left;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 28px;
        color: rgba(255,255,255,1);
        white-space:nowrap;
	}
	.playerBSecondLine {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 35px;
		left: 778.932px;
		top: 12.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	.playerBFirstLine {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 35px;
		left: 697.447px;
		top: 12.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	.playerBFirstLine_z {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 35px;
		left: 268.26px;
		top: 12.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#BWins {
		opacity: 0.996;
		position: absolute;
		left: 15px;
		top: 0px;
		overflow: visible;
		width: 63px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.playerBFirstLine_ {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 35px;
		left: 79.26px;
		top: 10.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#PlayerA {
		position: absolute;
		width: 881px;
		height: 55px;
		left: 9px;
		top: 45px;
		overflow: visible;
	}
	#AMatchScore {
		opacity: 0.996;
		position: absolute;
		left: 704px;
		top: 2px;
		overflow: visible;
		width: 63px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.white_paddle_ {
		overflow: visible;
		position: absolute;
		width: 24.255px;
		height: 20px;
		left: 856.382px;
		top: 18.652px;
		transform: matrix(1,0,0,1,0,0);
	}
	#ATimeout {
		position: absolute;
		width: 26px;
		height: 21px;
		left: 855px;
		top: 18px;
		overflow: visible;
	}
	.Rectangle_4_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 44px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#T_ {
		position: absolute;
		left: 0px;
		top: 0px;
		overflow: visible;
		width: 26px;
		height: 21px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Timeout_ {
		position: absolute;
		left: 0px;
		top: 16px;
		overflow: hidden;
		width: 26px;
		height: 3px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 4px;
		color: rgba(0,0,0,1);
	}
	#PlayerAName {
		position: absolute;
		left: 280px;
		top: 9px;
		overflow: visible;
		width: 417px;
		height: 40px;
		line-height: 60px;
		margin-top: -12.5px;
		text-align: left;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		color: rgba(255,255,255,1);
	}
	#TeamAName {
		position: absolute;
		left: 91px;
		top: 11px;
		overflow: visible;
		width: 173px;
		height: 40px;
		line-height: 60px;
		margin-top: -16px;
		text-align: left;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 28px;
        color: rgba(255,255,255,1);
        white-space:nowrap;
	}
	#PlayerAScore {
		opacity: 0.996;
		position: absolute;
		left: 786px;
		top: 2px;
		overflow: visible;
		width: 71px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.ColorA {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 40px;
		left: 0px;
		top: 9px;
	}
	.Line_5 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 34px;
		left: 778.936px;
		top: 8.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	.Line_3 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 34px;
		left: 697.453px;
		top: 8.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	.Line_6 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 34px;
		left: 268.265px;
		top: 8.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	.Line_17 {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 34px;
		left: 79.265px;
		top: 9.5px;
		transform: matrix(1,0,0,1,0,0);
	}
	#AWins {
		opacity: 0.996;
		position: absolute;
		left: 15px;
		top: 0px;
		overflow: visible;
		width: 63px;
		height: 53px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 45px;
		color: rgba(255,255,255,1);
	}
	.BottomBorder {
		overflow: visible;
		position: absolute;
		width: 863px;
		height: 1px;
		left: 24px;
		top: 150px;
		transform: matrix(1,0,0,1,0,0);
	}
	.MiddleBorder {
		overflow: visible;
		position: absolute;
		width: 863px;
		height: 1px;
		left: 24px;
		top: 98px;
		transform: matrix(1,0,0,1,0,0);
	}
	.TopBorder {
		overflow: visible;
		position: absolute;
		width: 863px;
		height: 1px;
		left: 24px;
		top: 45px;
		transform: matrix(1,0,0,1,0,0);
	}
	#TournamentName {
		position: absolute;
		left: 23.5px;
		top: 7.5px;
		overflow: visible;
		width: 865px;
		height: 38px;
		text-align: center;
		font-family: Helvetica Neue;
		font-style: normal;
		font-weight: normal;
		font-size: 30px;
		color: rgba(255,255,255,1);
	}` }}>
	
</style>


<body>
<div id="LeagueScoreboard">
	<div id="League_Scoreboard" class="League_Scoreboard">
		<svg class="MainBackground_l" viewBox="0 0 904 159">
			<radialGradient id="MainBackground_l" spreadMethod="pad" x1="0" x2="0" y1="0" y2="0">
				<stop offset="0" stop-color="#5b7ef4" stop-opacity="1"></stop>
				<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
				<stop offset="0" stop-color="#98a2e6" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#2d40d5" stop-opacity="1"></stop>
			</radialGradient>
			<path fill="url(#MainBackground_l)" stroke="rgba(112,112,112,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="MainBackground_l" d="M 8 0 L 896 0 C 900.4182739257812 0 904 3.581721782684326 904 8 L 904 151 C 904 155.4182739257812 900.4182739257812 159 896 159 L 8 159 C 3.581721782684326 159 0 155.4182739257812 0 151 L 0 8 C 0 3.581721782684326 3.581721782684326 0 8 0 Z">
			</path>
		</svg>
		<div id="PlayerB">
			<div id="BMatchScore">
				<span>{props.playerBMatchScore}</span>
			</div>
            {
                !props.AisServing ? <svg class="white_paddle" viewBox="-0.075 0.025 24.255 20">
				<path fill="rgba(255,255,255,1)" id="white_paddle" d="M 23.43134117126465 11.60492324829102 C 24.92336082458496 8.566158294677734 24.17971992492676 4.953229904174805 21.1956729888916 2.488627672195435 C 17.22168159484863 -0.7962074875831604 10.77519416809082 -0.7962074875831604 6.801201820373535 2.488627672195435 L 4.148717403411865 4.679818630218506 L 14.16658401489258 12.94073009490967 C 16.35015106201172 10.51518630981445 20.39519309997559 9.917590141296387 23.43134117126465 11.60492324829102 Z M 13.11032867431641 14.71790218353271 L 2.670903921127319 6.113272666931152 C 1.377817749977112 7.882630348205566 1.643065929412842 10.21443367004395 3.490333080291748 11.74162769317627 L 5.124453067779541 13.09305858612061 L 0.245774433016777 16.58490562438965 C -0.1615715175867081 16.87784576416016 -0.1852544248104095 17.39341926574707 0.1984085887670517 17.70979118347168 L 2.727742671966553 19.79943084716797 C 3.106669664382935 20.11580848693848 3.731898307800293 20.09628105163574 4.087142467498779 19.76037406921387 L 8.316908836364746 15.72561264038086 L 10.00786590576172 17.1200065612793 C 10.94097423553467 17.88946342468262 12.12038230895996 18.3112964630127 13.33768367767334 18.42066192626953 C 13.00138568878174 17.75666236877441 12.81665992736816 17.03017044067383 12.81665992736816 16.26852989196777 C 12.81192398071289 15.72951889038086 12.93507671356201 15.21394443511963 13.11032867431641 14.71790218353271 Z M 19.63260269165039 12.52280139923096 C 17.12221527099609 12.52280139923096 15.08548545837402 14.20232391357422 15.08548545837402 16.27243614196777 C 15.08548545837402 18.34254455566406 17.12221527099609 20.02206611633301 19.63260269165039 20.02206611633301 C 22.14299201965332 20.02206611633301 24.17971992492676 18.34254455566406 24.17971992492676 16.27243614196777 C 24.17971992492676 14.20232391357422 22.14299201965332 12.52280139923096 19.63260269165039 12.52280139923096 Z">
				</path>
			</svg> :null
            }
			
			<div id="PlayerBName">
				<span>{props.editing ? "--" : props.isDoubles ? (props.PlayerBLastName.length >= 1 && props.PlayerB2LastName.length >= 1) ? props.PlayerBLastName + "/" + props.PlayerB2LastName : props.PlayerBFirstName + "/" + props.PlayerB2FirstName : props.PlayerBFirstName + " " + props.PlayerBLastName}</span>
			</div>
			<div id="PlayerBScore">
				<span>{props.playerBScore}</span>
			</div>
			<svg class="ColorB">
				<rect fill={props.ColorB} id="ColorB" rx="6" ry="6" x="0" y="0" width="17" height="40">
				</rect>
			</svg>
            {
                props.BTimeOutActive ? <div id="BTimeout">
				<svg class="Rectangle_4">
					<rect fill="rgba(255,255,255,1)" id="Rectangle_4" rx="2" ry="2" x="0" y="0" width="26" height="21">
					</rect>
				</svg>
				<div id="T">
					<span>T</span>
				</div>
				<div id="Timeout">
					<span>Timeout</span>
				</div>
			</div>: null 
            }
			
			<div id="TeamBName">
				<span>{props.editing ? "--" :props.TeamBNameLeague}</span>
			</div>
			<svg class="playerBSecondLine" viewBox="0 0 1 35">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="playerBSecondLine" d="M 0 0 L 0 35">
				</path>
			</svg>
			<svg class="playerBFirstLine" viewBox="0 0 1 35">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="playerBFirstLine" d="M 0 0 L 0 35">
				</path>
			</svg>
			<svg class="playerBFirstLine_z" viewBox="0 0 1 35">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="playerBFirstLine_z" d="M 0 0 L 0 35">
				</path>
			</svg>
			<div id="BWins">
				<span>{props.TeamBWins}</span>
			</div>
			<svg class="playerBFirstLine_" viewBox="0 0 1 35">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="playerBFirstLine_" d="M 0 0 L 0 35">
				</path>
			</svg>
		</div>
		<div id="PlayerA">
			<div id="AMatchScore">
				<span>{props.playerAMatchScore}</span>
			</div>
            {
               props.AisServing ?  <svg class="white_paddle_" viewBox="-0.075 0.025 24.255 20">
				<path fill="rgba(255,255,255,1)" id="white_paddle_" d="M 23.43134117126465 11.60492324829102 C 24.92336082458496 8.566158294677734 24.17971992492676 4.953229904174805 21.1956729888916 2.488627672195435 C 17.22168159484863 -0.7962074875831604 10.77519416809082 -0.7962074875831604 6.801201820373535 2.488627672195435 L 4.148717403411865 4.679818630218506 L 14.16658401489258 12.94073009490967 C 16.35015106201172 10.51518630981445 20.39519309997559 9.917590141296387 23.43134117126465 11.60492324829102 Z M 13.11032867431641 14.71790218353271 L 2.670903921127319 6.113272666931152 C 1.377817749977112 7.882630348205566 1.643065929412842 10.21443367004395 3.490333080291748 11.74162769317627 L 5.124453067779541 13.09305858612061 L 0.245774433016777 16.58490562438965 C -0.1615715175867081 16.87784576416016 -0.1852544248104095 17.39341926574707 0.1984085887670517 17.70979118347168 L 2.727742671966553 19.79943084716797 C 3.106669664382935 20.11580848693848 3.731898307800293 20.09628105163574 4.087142467498779 19.76037406921387 L 8.316908836364746 15.72561264038086 L 10.00786590576172 17.1200065612793 C 10.94097423553467 17.88946342468262 12.12038230895996 18.3112964630127 13.33768367767334 18.42066192626953 C 13.00138568878174 17.75666236877441 12.81665992736816 17.03017044067383 12.81665992736816 16.26852989196777 C 12.81192398071289 15.72951889038086 12.93507671356201 15.21394443511963 13.11032867431641 14.71790218353271 Z M 19.63260269165039 12.52280139923096 C 17.12221527099609 12.52280139923096 15.08548545837402 14.20232391357422 15.08548545837402 16.27243614196777 C 15.08548545837402 18.34254455566406 17.12221527099609 20.02206611633301 19.63260269165039 20.02206611633301 C 22.14299201965332 20.02206611633301 24.17971992492676 18.34254455566406 24.17971992492676 16.27243614196777 C 24.17971992492676 14.20232391357422 22.14299201965332 12.52280139923096 19.63260269165039 12.52280139923096 Z">
				</path>
			</svg> :null
            }
			{
                props.ATimeOutActive ? <div id="ATimeout">
				<svg class="Rectangle_4_">
					<rect fill="rgba(255,255,255,1)" id="Rectangle_4_" rx="2" ry="2" x="0" y="0" width="26" height="21">
					</rect>
				</svg>
				<div id="T_">
					<span>T</span>
				</div>
				<div id="Timeout_">
					<span>Timeout</span>
				</div>
			</div> : null
            }
			
			<div id="PlayerAName">
				<span>{props.editing ? "--" :props.isDoubles ? (props.PlayerALastName.length >= 1 && props.PlayerA2LastName.length >= 1) ? props.PlayerALastName+"/"+props.PlayerA2LastName : props.PlayerAFirstName+"/"+props.PlayerA2FirstName :props.PlayerAFirstName+" "+props.PlayerALastName}</span>
			</div>
			<div id="TeamAName">
				<span>{props.editing ? "--" :props.TeamANameLeague}</span>
			</div>
			<div id="PlayerAScore">
				<span>{props.playerAScore}</span>
			</div>
			<svg class="ColorA">
				<rect fill={props.ColorA} id="ColorA" rx="6" ry="6" x="0" y="0" width="17" height="40">
				</rect>
			</svg>
			<svg class="Line_5" viewBox="0 0 1 34">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Line_5" d="M 0 0 L 0 34">
				</path>
			</svg>
			<svg class="Line_3" viewBox="0 0 1 34">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Line_3" d="M 0 0 L 0 34">
				</path>
			</svg>
			<svg class="Line_6" viewBox="0 0 1 34">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Line_6" d="M 0 0 L 0 34">
				</path>
			</svg>
			<svg class="Line_17" viewBox="0 0 1 34">
				<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="Line_17" d="M 0 0 L 0 34">
				</path>
			</svg>
			<div id="AWins">
				<span>{props.TeamAWins}</span>
			</div>
		</div>
		<svg class="BottomBorder" viewBox="0 0 863 1">
			<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="BottomBorder" d="M 0 0 L 863 0">
			</path>
		</svg>
		<svg class="MiddleBorder" viewBox="0 0 863 1">
			<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="MiddleBorder" d="M 0 0 L 863 0">
			</path>
		</svg>
		<svg class="TopBorder" viewBox="0 0 863 1">
			<path fill="transparent" stroke="rgba(185,185,185,1)" stroke-width="1px" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4" shape-rendering="auto" id="TopBorder" d="M 0 0 L 863 0">
			</path>
		</svg>
		<div id="TournamentName">
			<span>{props.TournamentName}</span>
		</div>
	</div>
</div>
</body>
</div>


    )
}
export default StreamV2LeagueScoreboard