import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Typography } from '@material-ui/core';

export default class TimeOut extends React.Component {

    state = {
        seconds: 60,

    }

    subtractOne() {
        this.setState({seconds: this.state.seconds -1})
    }

    componentWillMount() {
        var interval = setInterval(() => {
            this.subtractOne()
        }, 1000);
        this.setState({interval: interval})
    }

    componentDidUpdate() {
        if (this.state.seconds == 0) {
            clearInterval(this.state.interval)
            
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }
    render() {
        return (
            <Dialog open  PaperProps={{ display: "inline-flex", justifyContent:"center", alignItems:"center",verticalAlign:"middle" }}  >
                <Typography style={{fontSize:"8vw"}}>Timeout:</Typography>
                <Typography align="center" style={{fontSize:"8vw"}}>{this.props.name}</Typography>
                <Typography align="center" style={{fontSize:"15vw"}}>{this.state.seconds}</Typography>
            </Dialog>
        )
    }
}