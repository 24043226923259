import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import firebase from 'firebase';

import servicePhoto from './paddle.svg'




export default class TeamOnlyStreamScoreboard extends React.Component {

    state = {
        AHasTimeOut: false,

        AServes: null,
        ATimeOutActive: null,
        AisServing: null,
        BTimeOutActive: null,
        ColorA: "",
        ColorB: "",
        PlayerA2FirstName: "",
        PlayerA2LastName: "",
        PlayerAFirstName: "",
        PlayerALastName: "",
        PlayerB2FirstName: "",
        PlayerB2LastName: "",
        PlayerBFirstName: "",
        PlayerBLastName: "",
        TeamBName: "",
        TournamentName: "",
        active: null,
        bestOf5or7: "",
        competitionRound: "",
        editing: false,
        isDoubles: null,
        isInternationalTournament: null,
        isTeamTournament: null,
        playerAMatchScore: 0,
        playerAScore: 0,
        playerBMatchScore: 0,
        playerBScore: 0,
        TeamAWins: 0,
        TeamBWins: 0,
        status: "inactive",
        switch: false,
        tableName: "Table 1",
        tournamentName: "",
        magnifier: 1,
        styles: {

            showJerseyColors: true,
            transformSkewDegrees: 0,
            componentSpacing: 0,
            teamNameBackgroundColor: "navy",
            //teamBBackgroundColor: "navy",
            playerNameBackgroundColor: "navy",
            // playerBNameBackgroundColor: "navy",
            matchScoreBackgroundColor: "navy",
            gameScoreBackgroundColor: "navy",
            teamScoreBackgroundColor: "navy",

            fontSize: 32, //Default 32

            teamNameWidthDefault: 200,
            playerNameWidthDefault: 300,
            imgWidthDefault: 120,

            teamNameWidth: 200, //200
            playerNameWidth: 300, //300
            teamScoreWidth: 50, //50
            matchScoreWidth: 50, //50
            gameScoreWidth: 50,//50

            teamScoreColor: "white", //white
            matchScoreColor: "white",//white
            gameScoreColor: "white",//white
            teamNameColor: "white",//white
            playerNameColor: "white",//white
            roundedInternalEdges: 0,// 0
            borderWidth: 0, //0
            borderColor: "white", //white
            borderStyle: "solid", //solid
            serviceIconColor: "navy",//"#03006D"
            serviceIconWidth: 40, //40
            serviceIconHeight: 50,//50

            jerseyColorWidth: 20, //20
            universalHeight: 60, // 60

            hasImg: false,
            imgWidth: 120,
            imgURL: "https://livettscoreboard.com/wp-content/uploads/2020/08/512.png"

        }

    }


    magnifySize(magnifier, addOrMinus) {

        var newValues = {
            teamNameWidth: this.state.styles.teamNameWidth,
            playerNameWidth: this.state.styles.playerNameWidth,
            teamScoreWidth: this.state.styles.teamScoreWidth,
            matchScoreWidth: this.state.styles.matchScoreWidth,
            gameScoreWidth: this.state.styles.gameScoreWidth,
            fontSize: this.state.styles.fontSize, //Default 32
            universalHeight: this.state.styles.universalHeight,
            jerseyColorWidth: this.state.styles.jerseyColorWidth,
            serviceIconWidth: this.state.styles.serviceIconWidth,
            serviceIconHeight: this.state.styles.serviceIconHeight,
            imgWidth: this.state.styles.imgWidth
        }

        for (const key in newValues) {
            if (addOrMinus == "add") {
                newValues[key] = newValues[key] + newValues[key] * 0.1
            }
            if (addOrMinus == "minus") {
                newValues[key] = newValues[key] - newValues[key] * 0.1
            }



        }

        var newStyle = {
            ...this.state.styles,
            ...newValues

            /* teamNameWidth: this.state.styles.teamNameWidthDefault+(),
                 playerNameWidth: this.state.styles.playerNameWidthDefault*magnifier,
                 teamScoreWidth: 50*magnifier,
                 matchScoreWidth: 50*magnifier,
                 gameScoreWidth: 50*magnifier,
                 fontSize: 32*magnifier, //Default 32
                 universalHeight: 60*magnifier,
                 jerseyColorWidth: 20*magnifier,
                 serviceIconWidth: 40*magnifier,
                 serviceIconHeight: 50*magnifier,
                 imgWidth: this.state.styles.imgWidthDefault* magnifier
     */
        }

        this.setState({ styles: newStyle })

    }


    serviceIcon = (props) => {
        return (
            <svg width={props.width} height={props.height} viewBox="0 0 512 512" fill="none" class='paddle' xmlns="http://www.w3.org/2000/svg">
                <path d="M446.877 65.2196C508.079 126.519 524.017 215.964 494.764 291.97C430.656 251.027 347.029 266.076 300.291 326.149L93.3407 119.198L147.223 65.2194L147.224 65.2188C229.952 -17.7063 364.148 -17.7063 446.876 65.2188L446.877 65.2196ZM278.431 467.368C255.143 463.846 232.823 453.481 214.921 435.579L179.221 399.879L176.94 397.598L174.83 400.038L85.539 503.328C85.5381 503.329 85.5372 503.33 85.5364 503.331C79.1642 510.635 67.9878 511.03 61.2344 504.192L61.2289 504.186L61.2233 504.181L7.8233 450.681L7.82131 450.679C0.957737 443.815 1.37396 432.63 8.66749 426.265C8.66892 426.263 8.67035 426.262 8.67178 426.261L111.666 336.866L114.094 334.758L111.824 332.482L77.3244 297.882L77.324 297.881C40.2766 260.739 34.15 204.498 58.5377 160.779L274.929 377.072C271.441 389.42 269.003 402.352 269.1 415.909C269.1 415.913 269.1 415.917 269.1 415.922L278.431 467.368ZM323 416C323 364.657 364.657 323 416 323C467.343 323 509 364.657 509 416C509 467.343 467.343 509 416 509C364.657 509 323 467.343 323 416Z" fill={props.fill} stroke="white" stroke-width="6" />
            </svg>

        )
    }



    componentWillMount() {
        //console.log(this.props.match.params)
        if (this.props.match.params.staticurl) {
            firebase.database().ref("staticurl").orderByChild("staticurl").equalTo(this.props.match.params.staticurl).on("value", (val) => {
                console.log(val.val())
                if (val.val()) {
                    if (val.val()[Object.keys(val.val())[0]].tournamentID) {
                        var tournamentID = val.val()[Object.keys(val.val())[0]].tournamentID
                        firebase.database().ref("tournaments").child(tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
                            this.setState(val.val())

                            //console.log(val.val())
                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("TeamAWins").on("value", (wins) => {
                            if (typeof wins.val() != "undefined") {

                                this.setState({ TeamAWins: wins.val() })
                            }
                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("TeamBWins").on("value", (wins) => {
                            if (typeof wins.val() != "undefined") {
                                this.setState({ TeamBWins: wins.val() })
                            }
                        })
                        firebase.database().ref("tournaments").child(tournamentID).once("value", (val) => {
                            this.setState({ TournamentName: val.val().TournamentName })
                            if (val.val().liveStreamScoreboardID) {
                                firebase.database().ref("livestream").child(val.val().liveStreamScoreboardID).on("value", (style) => {
                                    this.setState({ styles: style.val() })
                                })
                            }

                        })
                        firebase.database().ref("tournaments").child(tournamentID).child("magnify").on("value", (magnification) => {
                            //console.log(magnification.val())
                            this.magnifySize(magnification.val().magnify, magnification.val().magDirection)
                        }
                        )
                    }
                    else {
                        this.setState({ PlayerAFirstName: "Assign a Tournament", })
                    }
                }
                else {
                    this.setState({ PlayerAFirstName: "Unassigned URL", })
                }
            })



        }
        else {

            var neededFields = ["AServes",
                "AisServing",
                "ColorA",
                "ColorB",
                "PlayerA2FirstName",
                "PlayerA2LastName",
                "PlayerAFirstName",
                "PlayerALastName",
                "PlayerB2FirstName",
                "PlayerB2LastName",
                "PlayerBFirstName",
                "PlayerBLastName",
                "editing",
                "isDoubles",
                "isTeamTournament",
                "playerAMatchScore",
                "playerAScore",
                'playerBMatchScore',
                "playerBScore",

            ]

            neededFields.forEach((field, index) => {
                firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).child(field).on("value", (val) => {
                    this.setState({ [field]: val.val() })
                })
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).once("value", (val) => {

                var tableData = val.val()
                if (tableData["isLeagueGame"]) {
                    delete tableData["TeamAName"]
                    delete tableData["TeamBName"]
                }
                else {
                    var teamNameFields = [
                        "TeamAName",
                        "TeamBName",
                    ]

                    teamNameFields.forEach((field, index) => {
                        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).child(field).on("value", (val) => {
                            this.setState({ [field]: val.val() })
                        })
                    })

                }

                this.setState(tableData)

                //console.log(val.val())
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAWins").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {

                    this.setState({ TeamAWins: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBWins").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {
                    this.setState({ TeamBWins: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAName").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {

                    this.setState({ TeamAName: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBName").on("value", (wins) => {
                if (typeof wins.val() != "undefined") {
                    this.setState({ TeamBName: wins.val() })
                }
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).once("value", (val) => {
                this.setState({ TournamentName: val.val().TournamentName })
                firebase.database().ref("livestream").child(val.val().liveStreamScoreboardID).on("value", (style) => {
                    this.setState({ styles: style.val() })
                })
            })
            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("magnify").on("value", (magnification) => {
                //console.log(magnification.val())
                this.magnifySize(magnification.val().magnify, magnification.val().magDirection)
            }
            )
        }


    }

    componentDidMount() {


    }




    render() {


        //console.log(this.state)
        return (
            <div >

                {
                    /*
<Paper  style={{float:"left", width:this.state.isTeamTournament? 700 :475, height:50,backgroundColor:"navy", margin: this.state.styles.componentSpacing, marginLeft:20, justifyContent:"center", alignItems:"center", transform:"skew(-20deg)"}}>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.TournamentName}</Typography>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.tableName} - {this.state.competitionRound}</Typography>
                    </Paper>
 
                    */
                }



                <div style={{
                    float: "left",
                    width: 20,
                    height: this.state.styles.universalHeight * 2
                }}></div>
                {
                    this.state.styles.hasImg ? <img style={{ float: "left" }} src={this.state.styles.imgURL} width={this.state.styles.imgWidth} height={this.state.styles.universalHeight * 2} /> : null
                }
                <div style={{ display: "table", }}>

                    {
                        this.state.styles.showJerseyColors ? <div style={{
                            float: "left",
                            width: this.state.styles.jerseyColorWidth,
                            height: this.state.styles.universalHeight,
                            borderWidth: this.state.styles.borderWidth,
                            borderColor: this.state.styles.borderColor,
                            borderStyle: this.state.styles.borderStyle,
                            borderRadius: this.state.styles.roundedInternalEdges,
                            backgroundColor: this.state.ColorA, margin: this.state.styles.componentSpacing,

                            transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)"
                        }}>
                        </div> : null
                    }


                    
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team A NAME ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    {
                        this.state.isTeamTournament ? <div style={{
                            float: "left",
                            width: this.state.styles.teamNameWidth,
                            height: this.state.styles.universalHeight,
                            display: "inline-flex",
                            backgroundColor: this.state.styles.teamNameBackgroundColor,
                            alignItems: "center",
                            verticalAlign: "middle",
                            borderWidth: this.state.styles.borderWidth,
                            borderColor: this.state.styles.borderColor,
                            borderStyle: this.state.styles.borderStyle,
                            borderRadius: this.state.styles.roundedInternalEdges,
                            paddingLeft: 10,
                            margin: this.state.styles.componentSpacing,
                            transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)"
                        }}>
                            <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamNameColor }}>{this.state.TeamAName}</Typography>
                        </div> : null
                    }
{
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team A SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }

                    {
                        this.state.isLeagueGame ? <div style={{
                            float: "left",
                            width: this.state.styles.teamScoreWidth,
                            height: this.state.styles.universalHeight,
                            display: "inline-flex",
                            backgroundColor: this.state.styles.teamScoreBackgroundColor,
                            justifyContent: "center",
                            alignItems: "center",
                            borderWidth: this.state.styles.borderWidth,
                            borderColor: this.state.styles.borderColor,
                            borderStyle: this.state.styles.borderStyle,
                            verticalAlign: "middle",
                            borderRadius: this.state.styles.roundedInternalEdges,
                            margin: this.state.styles.componentSpacing,
                            transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)"
                        }}>
                            <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamScoreColor }}>{this.state.TeamAWins}</Typography>
                        </div>
                            : null
                    }
                </div>

                {
                    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                    ////////////////////////////// PLAYER B STARTS HERE ////////////////////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                }

                <div style={{ display: "table" }}>

                    {
                        this.state.styles.showJerseyColors ? <div style={{
                            float: "left", width: this.state.styles.jerseyColorWidth, height: this.state.styles.universalHeight, borderRadius: this.state.styles.roundedInternalEdges,
                            borderWidth: this.state.styles.borderWidth,
                            borderColor: this.state.styles.borderColor,
                            borderStyle: this.state.styles.borderStyle,
                            backgroundColor: this.state.ColorB, margin: this.state.styles.componentSpacing, transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)",
                            outlineColor: "white", outlineWidth: "6px"
                        }}>
                        </div> : null
                    }


                    
                    {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team B NAME ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }

                    {this.state.isTeamTournament ? <div style={{
                        float: "left",
                        width: this.state.styles.teamNameWidth, height: this.state.styles.universalHeight,
                        display: "inline-flex",
                        backgroundColor: this.state.styles.teamNameBackgroundColor,
                        alignItems: "center",
                        borderWidth: this.state.styles.borderWidth,
                        borderColor: this.state.styles.borderColor,
                        borderStyle: this.state.styles.borderStyle,
                        borderRadius: this.state.styles.roundedInternalEdges,
                        verticalAlign: "middle",
                        paddingLeft: 10,
                        margin: this.state.styles.componentSpacing,
                        transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)"
                    }}>
                        <Typography noWrap style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamNameColor }}>{this.state.TeamBName}</Typography>
                    </div> : null
                    }

                   {
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////// Team B SCORE ////////////////////////////////////////////////////////////////////////////////
                        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 
                    }
                    {
                        this.state.isLeagueGame ? <div style={{
                            float: "left",
                            width: this.state.styles.teamScoreWidth,
                            height: this.state.styles.universalHeight,
                            display: "inline-flex",
                            backgroundColor: this.state.styles.teamScoreBackgroundColor,
                            justifyContent: "center",
                            borderRadius: this.state.styles.roundedInternalEdges,
                            alignItems: "center",
                            borderWidth: this.state.styles.borderWidth,
                            borderColor: this.state.styles.borderColor,
                            borderStyle: this.state.styles.borderStyle,
                            verticalAlign: "middle",
                            margin: this.state.styles.componentSpacing,
                            transform: "skew(" + this.state.styles.transformSkewDegrees + "deg)"
                        }}>
                            <Typography style={{ fontSize: this.state.styles.fontSize, color: this.state.styles.teamScoreColor }}>{this.state.TeamBWins}</Typography>
                        </div>
                            : null
                    }


                </div>



            </div>



        )
    }
}