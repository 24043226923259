import React from 'react';
import firebase from 'firebase';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

import logo from "./Framelinearlogo.png";
import appleLogo from './apple.png'
import playstore from './playstore.png'
import { Paper, Card, CardContent, CardHeader, CardActionArea } from '@material-ui/core';
import SingleTournament from './singleTournament';

const styles = {
    body: {
        width: "100%",
        height: "100%",
        backgroundColor: "#3968CC"
    }
}

class StaticHomeScreen extends React.Component {



    state = {
        tournamentList: [],
        upcomingTournamentList: [],
        oldTournaments: [],
        loadingDone: false,
        width: window.innerWidth / 2 < 750 ? window.innerWidth : window.innerWidth / 2,
        height: window.innerHeight
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth / 2 < 750 ? window.innerWidth : window.innerWidth / 2, height: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));


    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))
    }

    componentWillMount() {
        document.body.style.backgroundColor = "#3968CC";
        // API call for Scoreboards Goes here
        //console.log("Running component will mount")
        firebase.database().ref("staticurl").orderByChild("staticurl").equalTo(this.props.match.params.staticurl).on("value", (value) =>{
            console.log(value.val())
            var key = Object.keys(value.val())[0]
            var staticTournamentID = value.val()[key].tournamentID

            console.log(staticTournamentID)
            if(staticTournamentID ){
             firebase.database().ref("tournaments").child(staticTournamentID).on('value', (val) => {
            console.log("The list of val.val", val.val())
            var oldTournaments = []
            var upcomingTournys = []
            var tournamentList = Object.values(val.val())
            tournamentList = tournamentList.filter((val) => {
                //console.log("val in the filter", val)
                return !val.deleted
            })
            tournamentList = [val.val()]


            tournamentList.map((item) => {
                var tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate())
                // if(new Date(item.endDate) < tomorrow) {
                //     
                // }
                if (new Date(item.endDate) > tomorrow) {
                    upcomingTournys.push(item)
                }
                if (new Date(item.endDate) < tomorrow) {
                    oldTournaments.push(item)
                }
            })
            upcomingTournys.sort((a, b) => {
                var DateA = new Date(a.startDate)
                var DateB = new Date(b.startDate)
                if (DateA > DateB) {
                    return 1
                }
                else if (DateB > DateA) {
                    return -1
                }
            }
            )
            oldTournaments.sort((a, b) => {
                var DateA = new Date(a.startDate)
                var DateB = new Date(b.startDate)
                if (DateA > DateB) {
                    return -1
                }
                else if (DateB > DateA) {
                    return 1
                }
            }
            )



            //console.log(tournamentList)

            this.setState({ tournamentList: tournamentList, loadingDone: true, upcomingTournamentList: upcomingTournys, oldTournaments: oldTournaments })




        })

    }
    else {
        this.setState({loadingDone:true, showNotAssignedError: true})

    }
        })
       
        //API call for tournaments goes here


    }


    render() {
        //document.body.style = 'background: #3968CC;';

        if (this.state.loadingDone) {
            return (
                <div style={{ width: "100%", background: "#3968CC", height: this.state.height, backgroundSize: "cover", flexGrow: 1, }} >

                    <div style={{   alignItems: "center", display: "flex", justifyContent:"space-between" }}>
                        <img src={logo} style={{height:"auto", width:"33%"}}  />
                    <div style={{width:"30%" ,flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                         <a  href="https://play.google.com/store/apps/details?id=com.livettscoreboard&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                            <img style={{ height: "auto", width: "45%" }} alt="Get it on Google Play" src={playstore} />
                        </a>
                        <a href="https://apps.apple.com/us/app/live-tt-scoreboard/id1480726505?ls=1">
                            <img src={appleLogo} style={{ height: "auto", width: "45%" }} />
                        </a>
                    </div>
                       
                    </div>
                    <div style={{ width: "49%", justifyContent: "center", display: "grid", alignItems: "center", }}>


                    </div><Typography align="center" style={{ color: "white", fontSize: "32", width:"100%", }}>To View Tournament Scores, or display a scoreboard, click your tournament below. For keeping score, download the iOS or Android App.</Typography>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                        
                        
                        <div style={{ width:  "95%" , overflow: "auto", minWidth: 300, maxWidth:600 }}>
                        {
                            this.state.showNotAssignedError ? <h1 style={{color:"white"}}>Tournament Not Assigned To Static URL</h1>
                            : null 
                        }

                            {
                                this.state.upcomingTournamentList.map((item) => {
                                    console.log(item)

                                    return (
                                        <SingleTournament key={item.TournamentName + item.id} data={item} expanded={true} isStatic={true} > 
        
                                                </SingleTournament>
                                    )
                                })
                            }


                        </div>

                    </div>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                       
                        <div style={{ width: this.state.width < 750 ? "99%" : "75%", overflow: "auto", minWidth: 300,  alignItems:"center", maxWidth:600 }}>
                            {
                                this.state.oldTournaments.map((item, index) => {
                                    return (
                                        <SingleTournament key={item.TournamentName + item.id} data={item} expanded={true} isStatic={true} > 
        
                                        </SingleTournament>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )

        }
        else {
            return (
                <div style={{ width: "100%", }} >
                    <div style={{ justifyContent: "center", width: "100%", alignItems: "center", display: "flex", flexDirection: "column" }}>
                        <img src={logo} style={{ width: "50%" }} />
                        <CircularProgress size={60} thickness={5} color="secondary" />
                    </div>

                </div>
            )
        }
    }
}

export default withStyles(styles)(StaticHomeScreen)