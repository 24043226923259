import React from 'react';
import logo from './logo.svg';
//import './App.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, Icon, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import firebase from 'firebase';

import backgroundImage from './blueBackground.jpg'
//import serviceIcon from './white-paddle.svg';
import TimeOutIcon from './Timeout.svg'
import TimeOut from './timeout';
import FullScreen from 'react-full-screen';


export default class CustomizedScoreBoard extends React.Component {

  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    timeout: false,
    fullscreen: false,


    AServes: null,
    ATimeOutActive: null,
    AisServing: null,
    BTimeOutActive: null,
    ColorA: "",
    ColorB: "",
    PlayerA2FirstName: "",
    PlayerA2LastName: "",
    PlayerAFirstName: "",
    PlayerALastName: "",
    PlayerB2FirstName: "",
    PlayerB2LastName: "",
    PlayerBFirstName: "",
    PlayerBLastName: "",
    TeamBName: "",
    TeamAName: "",
    TournamentName: "",
    active: null,
    bestOf5or7: "",
    competitionRound: "",
    editing: false,
    isDoubles: null,
    isInternationalTournament: null,
    isTeamTournament: null,
    playerAMatchScore: 0,
    playerAScore: 0,
    playerBMatchScore: 0,
    playerBScore: 0,
    status: "inactive",
    switch: false,
    tableName: "Table 1",
    tournamentName: "",



    showTournamentName: true, //
    showCompetitionRound: true,//
    showTeamNames: true,//
    fontSizeTournamentName: 6,//
    fontSizeTeamNames: 3,//
    fontSizeCompetitionRound: 3,//
    fontSizeNames: 3,//

    colorGameScoreText: "black",//
    colorMatchScoreText: "black",//
    colorGameScoreBackground: "white",//
    colorMatchScoreBackground: "white",//
    colorServiceIcon: "white",//
    colorNames: "white",//
    colorBackground: "blue",
    backgroundIsURL: true,
    backgroundURL: backgroundImage


  }

  updateDimensions() {
    if (this.state.fullscreen) {
      //console.log("Something")

    }
    else {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


  }

  componentWillMount() {
    // document.body.style.backgroundImage = `url(${backgroundImage})`
    //console.log("Mounting the ");
    //console.log(this.props.match.params)
    if( this.props.match.params.staticurl){
      firebase.database().ref("staticurl").orderByChild("staticurl").equalTo(this.props.match.params.staticurl).on("value", (val) =>{
console.log(val.val())
if(val.val()){
  if(val.val()[Object.keys(val.val())[0]].tournamentID){
  var tournamentID = val.val()[Object.keys(val.val())[0]].tournamentID
        firebase.database().ref("tournaments").child(tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
          //this.setState(val.val())
    
          firebase.database().ref("tournaments").child(tournamentID).child("TournamentName").on("value", (value) => {
            var newState = val.val()
    
            newState.tournamentName = value.val()
            console.log(newState)
            this.setState(newState)
          })
          //console.log(val.val())
        })
        firebase.database().ref("tournaments").child(tournamentID).child("scoreboardID").once("value", (value) => {
          if (value.val()) {
            firebase.database().ref("scoreboard").child(value.val()).on("value", (val) => {
              console.log(val.val())
    
              this.setState(val.val(), () => {
                console.log(this.state)
              })
            })
          }
    
        })
        
}
else{
  this.setState({tournamentName: "Assign a Tournament", TournamentName: "Assign a Tournament"})
}
}
else{
          this.setState({tournamentName: "Unassigned URL", TournamentName: "Unassigned URL"})
        }
        
      })
    }
    else{
      firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
      //this.setState(val.val())

      firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TournamentName").on("value", (value) => {
        var newState = val.val()

        newState.tournamentName = value.val()
        console.log(newState)
        this.setState(newState)
      })
      //console.log(val.val())
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("scoreboardID").once("value", (value) => {
      if (value.val()) {
        firebase.database().ref("scoreboard").child(value.val()).on("value", (val) => {
          console.log(val.val())

          this.setState(val.val(), () => {
            console.log(this.state)
          })
        })
      }

    })
    }
    
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));


  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  serviceIcon = (props) => {
    return (
      <svg width={props.width} height={props.height} viewBox="0 0 512 512" fill="none" class='paddle' xmlns="http://www.w3.org/2000/svg">
        <path d="M446.877 65.2196C508.079 126.519 524.017 215.964 494.764 291.97C430.656 251.027 347.029 266.076 300.291 326.149L93.3407 119.198L147.223 65.2194L147.224 65.2188C229.952 -17.7063 364.148 -17.7063 446.876 65.2188L446.877 65.2196ZM278.431 467.368C255.143 463.846 232.823 453.481 214.921 435.579L179.221 399.879L176.94 397.598L174.83 400.038L85.539 503.328C85.5381 503.329 85.5372 503.33 85.5364 503.331C79.1642 510.635 67.9878 511.03 61.2344 504.192L61.2289 504.186L61.2233 504.181L7.8233 450.681L7.82131 450.679C0.957737 443.815 1.37396 432.63 8.66749 426.265C8.66892 426.263 8.67035 426.262 8.67178 426.261L111.666 336.866L114.094 334.758L111.824 332.482L77.3244 297.882L77.324 297.881C40.2766 260.739 34.15 204.498 58.5377 160.779L274.929 377.072C271.441 389.42 269.003 402.352 269.1 415.909C269.1 415.913 269.1 415.917 269.1 415.922L278.431 467.368ZM323 416C323 364.657 364.657 323 416 323C467.343 323 509 364.657 509 416C509 467.343 467.343 509 416 509C364.657 509 323 467.343 323 416Z" fill={props.fill} stroke="white" stroke-width="6" />
      </svg>

    )
  }


  render() {

    document.body.style.background = this.state.backgroundIsURL ? `url(${backgroundImage})` : this.state.colorBackground
    return (

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      <FullScreen enabled={this.state.fullscreen} onChange={(enabled) => {

        this.setState({ fullscreen: enabled, width: window.innerWidth, height: window.innerHeight })
      }}>

        <Grid container style={{
          height: this.state.height, width: this.state.width, overflow: "visible",
          background: this.state.backgroundIsURL ? `url(${backgroundImage})` : this.state.colorBackground,
          // backgroundImage: `url(${backgroundImage})`, 
          backgroundSize: 'cover',
        }} >
          {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////  Top bar   /////////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          }

          <Typography>

            {
              this.state.showTournamentName ?
                <Typography align="center" noWrap style={{ fontSize: this.state.fontSizeTournamentName + "vw", width: this.state.width, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>{this.state.tournamentName}</Typography>
                : null
            }

            {
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              // Second Line, Team A, Stage, and Team B
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            }
            <div style={{ display: "table", }}>
              <div style={{ float: "left", width: this.state.width / 3, }}>
                {
                  this.state.showTeamNames ?
                    <Typography align="center" noWrap style={{ fontSize: this.state.fontSizeTeamNames + "vw", width: this.state.width / 3, minHeight: 1, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>{this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.TeamBName : this.state.TeamAName : this.state.switch ? this.state.TeamAName : this.state.TeamBName}</Typography>

                    : <div style={{  width: this.state.width / 3, minHeight:1,}}></div>
                }

              </div>
              <div style={{ float: "left", width: this.state.width / 3, }}>
                {
                  this.state.showCompetitionRound ?
                    <Typography align="center" noWrap style={{ fontSize: this.state.fontSizeCompetitionRound + "vw", width: this.state.width / 3, minHeight: 1, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>{this.state.editing ? "--" : this.state.competitionRound}</Typography>

                    : <div style={{  width: this.state.width / 3, minHeight:1,}}></div>
                }

              </div>
              <div style={{ float: "left", width: this.state.width / 3, }}>
                {
                  this.state.showTeamNames ?
                    <Typography align="center" noWrap style={{ fontSize: this.state.fontSizeTeamNames + "vw", width: this.state.width / 3, minHeight: 1, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>{this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.TeamAName : this.state.TeamBName : this.state.switch ? this.state.TeamBName : this.state.TeamAName}</Typography>
                    : <div style={{  width: this.state.width / 3, minHeight:1,}}></div>
                }

              </div>

            </div>
          </Typography>

          {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Player A Section
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          }

          <Grid item style={{ flexGrow: 1, width: this.state.width / 2, height: this.state.height * 0.75, }} >
            <Grid item >
              {
                //Player Names and Match Score
              }
              <div style={{ display: "table" }}>
                <div style={{ float: "left", width: this.state.width * 0.4 }}>
                  <Typography align="center" style={{ fontSize: this.state.fontSizeNames + "vw", height: this.state.width * 0.06, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>
                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ? this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName : this.state.PlayerBLastName : this.state.isDoubles ? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName : this.state.PlayerALastName : this.state.switch ? this.state.isDoubles ? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName : this.state.PlayerALastName : this.state.isDoubles ? this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName : this.state.PlayerBLastName}
                  </Typography>
                  <Typography align="center" style={{ fontSize: this.state.fontSizeNames + "vw", height: this.state.width * 0.08, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>
                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ? (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName : this.state.isDoubles ? this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName : this.state.switch ? this.state.isDoubles ? this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName : this.state.isDoubles ? (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName}
                  </Typography>
                </div>
                <div style={{ float: "left", width: this.state.width * 0.08, }}>
                  <Paper style={{ backgroundColor: this.state.colorMatchScoreBackground, display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", width: this.state.width * 0.08 }}>
                    <Typography style={{ fontSize: "10vw", color: this.state.colorMatchScoreText, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerBMatchScore : this.state.playerAMatchScore : this.state.switch ? this.state.playerAMatchScore : this.state.playerBMatchScore}</Typography>
                  </Paper >
                </div>
              </div>

              {
                // Player Score of the current game
              }
              <div style={{ display: "table" }} >

                <div style={{ paddingLeft: this.state.width * 0.05, float: "left" }}>
                  <Paper alignItems="center" justify="center" style={{ backgroundColor: this.state.colorGameScoreBackground, display: "inline-flex", justifyContent: "center", alignItems: "center", width: this.state.width * 0.3, verticalAlign: "middle", height: this.state.width * 0.22 }}>
                    <Typography style={{ fontSize: "20vw", color: this.state.colorGameScoreText, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerBScore : this.state.playerAScore : this.state.switch ? this.state.playerAScore : this.state.playerBScore}</Typography>
                  </Paper>
                </div>
                <div style={{ float: "left", width: this.state.width * 0.14, }}>
                  {
                    !this.state.scorboardDirectionAway ?
                      this.state.switch ? !this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>
                      :
                      this.state.switch ? this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div>

                  }
                  {
                    !this.state.scorboardDirectionAway ?
                      this.state.switch ? this.state.BTimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.ATimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>
                      :
                      this.state.switch ? this.state.ATimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.BTimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>


                  }

                </div>

              </div>




            </Grid>


            {
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              //Player B Side
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            }
          </Grid>
          <Grid item style={{ flexGrow: 1, width: this.state.width / 2, height: this.state.height * 0.75 }} >
            <Grid item  >

              {
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // Player B Name and Match Score
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

              }
              <div style={{ display: "table" }}>

                <div style={{ float: "left", width: this.state.width * 0.08, paddingLeft: this.state.width * 0.019 }}>
                  <Paper style={{ backgroundColor: this.state.colorMatchScoreBackground, display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", width: this.state.width * 0.08 }}>
                    <Typography style={{ fontSize: "10vw", color: this.state.colorMatchScoreText, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerAMatchScore : this.state.playerBMatchScore : this.state.switch ? this.state.playerBMatchScore : this.state.playerAMatchScore}</Typography>
                  </Paper >
                </div>
                <div style={{ float: "left", width: this.state.width * 0.4 }}>
                  <Typography align="center" style={{ fontSize: this.state.fontSizeNames + "vw", height: this.state.width * 0.06, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>
                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName : this.state.PlayerALastName : this.state.isDoubles ? this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName : this.state.PlayerBLastName : this.state.switch ? this.state.isDoubles ? this.state.PlayerBLastName.length >= 1 ? this.state.PlayerBLastName : this.state.PlayerBFirstName : this.state.PlayerBLastName : this.state.isDoubles ? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName : this.state.PlayerALastName}
                  </Typography>
                  <Typography align="center" style={{ fontSize: this.state.fontSizeNames + "vw", height: this.state.width * 0.08, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color: this.state.colorNames }}>
                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ? this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName : this.state.isDoubles ? (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName : this.state.switch ? this.state.isDoubles ? (this.state.PlayerB2LastName.length >= 1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName : this.state.isDoubles ? this.state.PlayerA2LastName.length >= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName}
                  </Typography>
                </div>

              </div>

              {
                //Player B Current Game Score                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.PlayerALastName: this.state.PlayerBLastName: this.state.switch ? this.state.PlayerBLastName : this.state.PlayerALastName }

              }

              <div style={{ display: "table" }} >
                <div style={{ float: "left", width: this.state.width * 0.14 }}>
                  {
                    !this.state.scorboardDirectionAway ?
                      this.state.switch ? this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div>
                      :
                      this.state.switch ? !this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.AisServing ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><this.serviceIcon width={this.state.width * 0.09} height={this.state.width * 0.09} fill={this.state.colorServiceIcon} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>

                  }
                  {
                    !this.state.scorboardDirectionAway ?
                      this.state.switch ? this.state.ATimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.BTimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>
                      :
                      this.state.switch ? this.state.BTimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div> :
                        this.state.ATimeOutActive ? <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14, justifyContent: "center", alignItems: "center", display: "flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>

                  }
                  <div style={{ height: this.state.width * 0.12, width: this.state.width * 0.14 }}></div>
                </div>
                <div style={{ paddingRight: this.state.width * 0.05, alignItems: "flex-end", float: "left" }}>
                  <Paper alignItems="center" justify="center" style={{ backgroundColor: this.state.colorGameScoreBackground, display: "inline-flex", justifyContent: "center", alignItems: "center", width: this.state.width * 0.3, verticalAlign: "middle", height: this.state.width * 0.22 }}>
                    <Typography style={{ fontSize: "20vw", color: this.state.colorGameScoreText, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerAScore : this.state.playerBScore : this.state.switch ? this.state.playerBScore : this.state.playerAScore}</Typography>


                  </Paper>

                </div>
              </div>

            </Grid>
          </Grid>
          {
            this.state.BTimeOutActive ? <TimeOut name={this.state.PlayerBLastName} width={this.state.width} /> : null

          }
          {
            this.state.ATimeOutActive ? <TimeOut name={this.state.PlayerALastName} width={this.state.width} /> : null
          }
          <Button
            style={{ position: "absolute", bottom: 0, left: 0, width: window.innerWidth, height: window.innerHeight }}
            onClick={() => { this.setState({ fullscreen: this.state.fullscreen ? false : true, width: window.innerWidth, height: window.innerHeight }) }}></Button>
        </Grid>
      </FullScreen>
    );

  }
}

/**
function App() {

  return (
    <div  >

    </div>
  );
}

export default App;
*/