import React from 'react';
import { GridList, Typography, Paper, List, ListItem, Card, GridListTile, Grid, AppBar, Tabs, Tab } from '@material-ui/core';
import OneTable from './onetable';
import firebase from 'firebase';
import { width, minWidth } from '@material-ui/system';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {Helmet} from 'react-helmet'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from "./Framelinearlogo.png";

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));

export default class WholeTournamentView extends React.Component {

    returnFinals() {
        try {
            //Current Finals Games
            return this.state.tables.map((item) => {
                if (item.competitionRound == "Finals") {
                    return (
                        <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                            numberOfGames={item.bestOf5or7}
                            playerAScore={item.playerAScore}
                            playerBScore={item.playerBScore}
                            BScores={item.BScores ? item.BScores : []}
                            AScores={item.AScores ? item.AScores : []}
                            playerBMatchScore={item.playerBMatchScore}
                            playerAMatchScore={item.playerAMatchScore}
                            isTeamTournament={item.isTeamTournament}
                            TeamAName={item.TeamAName}
                            TeamBName={item.TeamBName}
                            active={item.active}
                            playerA={item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName + "/" + item.PlayerA2LastName : item.PlayerAFirstName + "/" + item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName + ", " + item.PlayerAFirstName : item.PlayerAFirstName}
                            playerB={item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName + "/" + item.PlayerB2LastName : item.PlayerBFirstName + "/" + item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName + ", " + item.PlayerBFirstName : item.PlayerBFirstName}
                            editing={item.editing}
                        />
                    )

                }
            })
        } catch (error) {
            console.log("Didn't work")
        }
    }

    returnEndedFinals() {
        try {
            return this.state.finishedGames.Finals.map((item) => {

                return (
                    <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                        numberOfGames={item.bestOf5or7}
                        playerAScore={item.playerAScore}
                        playerBScore={item.playerBScore}
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament={item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA={item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName + "/" + item.PlayerA2LastName : item.PlayerAFirstName + "/" + item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName + ", " + item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB={item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName + "/" + item.PlayerB2LastName : item.PlayerBFirstName + "/" + item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName + ", " + item.PlayerBFirstName : item.PlayerBFirstName}
                        tableName={item.tableName}
                        competitionRound={item.competitionRound}
                        editing={item.editing}
                    />
                )


            })
        } catch (error) {
            console.log("Didn't work for ended finals")
        }
    }

    returnCurrentRound(round) {
        //console.log("Running", round)
        try {
            return this.state.tables.map((item) => {
                if (item.competitionRound == round) {
                    return (
                        <GridList style={{display:"flex", justifyContent:"center"}}>
                        <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                            numberOfGames={item.bestOf5or7}
                            playerAScore={item.playerAScore}
                            playerBScore={item.playerBScore}
                            BScores={item.BScores ? item.BScores : []}
                            AScores={item.AScores ? item.AScores : []}
                            playerBMatchScore={item.playerBMatchScore}
                            playerAMatchScore={item.playerAMatchScore}
                            isTeamTournament={item.isTeamTournament}
                            TeamAName={item.TeamAName}
                            TeamBName={item.TeamBName}
                            active={item.active}
                            playerA={item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName + "/" + item.PlayerA2LastName : item.PlayerAFirstName + "/" + item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName + ", " + item.PlayerAFirstName : item.PlayerAFirstName}
                            playerB={item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName + "/" + item.PlayerB2LastName : item.PlayerBFirstName + "/" + item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName + ", " + item.PlayerBFirstName : item.PlayerBFirstName}
                            competitionRound={item.competitionRound}
                            tableName={item.tableName}
                            editing={item.editing}
                        />
                        </GridList>
                    )

                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    returnFinishedLeagueGames(){
        try {
            var RoundList = Object.keys(this.state.finishedGames)

            return RoundList.map((round) =>{
                var keyList = Object.keys(this.state.finishedGames[round])

            

            return keyList.map((item) => {

                item = this.state.finishedGames[round][item]
                //console.log(item)
                return (
                    <GridList style={{display:"flex", justifyContent:"center"}}>
                    <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                        numberOfGames={item.bestOf5or7}
                        playerAScore={item.playerAScore}
                        playerBScore={item.playerBScore}
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament={item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA={item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName + "/" + item.PlayerA2LastName : item.PlayerAFirstName + "/" + item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName + ", " + item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB={item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName + "/" + item.PlayerB2LastName : item.PlayerBFirstName + "/" + item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName + ", " + item.PlayerBFirstName : item.PlayerBFirstName}
                        competitionRound={item.competitionRound}
                        tableName={item.tableName}
                        editing={item.editing}
                    />
                    </GridList>
                )


            })
            })
            //console.log(this.state.finishedGames[round])
            
        } catch (error) {
            console.log(error)
        }
    }
    returnFinishedGames(round) {
        //console.log("Running", round)
        try {

            //console.log(this.state.finishedGames[round])
            var keyList = Object.keys(this.state.finishedGames[round])

            

            return keyList.map((item) => {

                item = this.state.finishedGames[round][item]
                //console.log(item)
                return (
                    <GridList style={{display:"flex", justifyContent:"center"}}>
                    <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                        numberOfGames={item.bestOf5or7}
                        playerAScore={item.playerAScore}
                        playerBScore={item.playerBScore}
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament={item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA={item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName + "/" + item.PlayerA2LastName : item.PlayerAFirstName + "/" + item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName + ", " + item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB={item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName + "/" + item.PlayerB2LastName : item.PlayerBFirstName + "/" + item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName + ", " + item.PlayerBFirstName : item.PlayerBFirstName}
                        competitionRound={item.competitionRound}
                        tableName={item.tableName}
                        editing={item.editing}
                    />
                    </GridList>
                )


            })
        } catch (error) {
            console.log(error)
        }
    }

    currentRoundExists(round) {
        try {
            return this.state.tables.filter((item) => {
                return item.competitionRound === round
            })

        } catch (error) {
            console.log("currentRoundExists Error", error)
            return false
        }
    }



    finishedGameExists(round) {
        try {
            if (this.state.finishedGames[round]) {
                return true
            }
            else {
                return false
            }
        } catch (error) {
            //console.log("finishedGameExists Error", error)
            return false
        }
    }

    isGameInCompetition(){
        var hasGames = false
        for(var round of this.state.competitionsList){
            //console.log(round)
            if (hasGames){
                return true
            }
            else{
                //console.log(round, this.currentRoundExists(round))
                //console.log(round, this.finishedGameExists(round))
                if(this.currentRoundExists(round).length > 0  || this.finishedGameExists(round)){
                    return true
                }

            }
        }

        return false
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth / 2 < 500 ? window.innerWidth : window.innerWidth / 2, height: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));


    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))
    }

    componentWillMount() {
        //document.getElementById("body").style({backgroundColor:""})
        document.body.style.backgroundColor = "#3968CC";
        //document.body.style = 'background: #3968CC;';
        //console.log(this.props.match.params)

        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).on("value", (val) => {
            /** 
             * 
             * <meta property="og:title" content={this.state.TournamentName+" | Live TT Scoreboard"}></meta>
                    <meta property="og:image" content="https://livettscoreboard.com/content/images/2019/09/IMG_1735.jpg" ></meta>
                    <meta property="og:description" content={"Watch the live results for the "+this.state.TournamentName} ></meta>
                    <meta property="og:url" content={"https://tournament.livettscoreboard.com/"+this.state.id} ></meta>
            */
            if (this.state.tables.length < 1) {
                var imageMeta = document.createElement('meta');
                imageMeta.setAttribute("property", "og:image")
                imageMeta.setAttribute("content", "https://livettscoreboard.com/content/images/2019/09/IMG_1735.jpg")

                document.head.append(imageMeta)
            }
            
            this.setState(val.val())
            this.setState({initialLoadDone: true})
            //console.log(val.val())
        })
    }
    

    showSortByRound(){

                return(
                    <div>
                        {

this.finishedGameExists("League Match") || this.currentRoundExists("League Match").length >= 1 ?
    <ExpansionPanel expanded={this.state.finalsOpen} onClick={() =>{this.setState({finalsOpen: this.state.finalsOpen ? false:true})}}>
        <ExpansionPanelSummary  style={{backgroundColor:"#5A82D8"}}
            expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography style={{width:"100%", color:"white"}} align="center" >Finals</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
           
            {
                //Current Finals Games
                this.returnCurrentRound("League Match")
            }
            {
                //Finished SemiFinal
                this.returnFinishedGames("League Match")
            }
            
        </ExpansionPanelDetails>
    </ExpansionPanel>
    : null
}
                    {

                    this.finishedGameExists("Finals") || this.currentRoundExists("Finals").length >= 1 ?
                        <ExpansionPanel expanded={this.state.finalsOpen} onClick={() =>{this.setState({finalsOpen: this.state.finalsOpen ? false:true})}}>
                            <ExpansionPanelSummary  style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{width:"100%", color:"white"}} align="center" >Finals</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                               
                                {
                                    //Current Finals Games
                                    this.returnCurrentRound("Finals")
                                }
                                {
                                    //Finished SemiFinal
                                    this.returnFinishedGames("Finals")
                                }
                                
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null
                }

                {
                    this.finishedGameExists("Semifinals") || this.currentRoundExists("Semifinals").length >= 1 ?
                        <ExpansionPanel expanded={this.state.semifinalsOpen} onClick={() =>{this.setState({semifinalsOpen: this.state.semifinalsOpen ? false:true})}}>
                            <ExpansionPanelSummary  style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Semifinals</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    //Current Finals Games
                                    this.returnCurrentRound("Semifinals")
                                }
                                {
                                    //Finished SemiFinal
                                    this.returnFinishedGames("Semifinals")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null
                }

                {
                    this.finishedGameExists("Quarterfinals") || this.currentRoundExists("Quarterfinals").length >= 1 ?
                        <ExpansionPanel expanded={this.state.quarterfinalsOpen} onClick={() =>{this.setState({quarterfinalsOpen: this.state.quarterfinalsOpen ? false:true})}}>
                            <ExpansionPanelSummary style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Quarterfinals</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Quarterfinals")
                                }
                                {
                                    this.returnFinishedGames("Quarterfinals")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null
                }

                {
                    this.finishedGameExists("Round of 16") || this.currentRoundExists("Round of 16").length >= 1 ?
                        <ExpansionPanel expanded={this.state.r16Open} onClick={() =>{this.setState({r16Open: this.state.r16Open ? false:true})}}>
                            <ExpansionPanelSummary style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center"  >Round of 16</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Round of 16")
                                }
                                {
                                    this.returnFinishedGames("Round of 16")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null
                }

                {
                    this.finishedGameExists("Round of 32") || this.currentRoundExists("Round of 32").length >= 1 ?
                        <ExpansionPanel expanded={this.state.r32Open} onClick={() =>{this.setState({r32Open: this.state.r32Open ? false:true})}}>
                            <ExpansionPanelSummary  style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Round of 32</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Round of 32")
                                }
                                {
                                    this.returnFinishedGames("Round of 32")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null

                }
                {
                    this.finishedGameExists("Round of 64") || this.currentRoundExists("Round of 64").length >= 1 ?
                        <ExpansionPanel expanded={this.state.r64Open} onClick={() =>{this.setState({r64Open: this.state.r64Open ? false:true})}}>
                            <ExpansionPanelSummary style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Round of 64</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Round of 64")
                                }
                                {
                                    this.returnFinishedGames("Round of 64")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null

                }

                {
                    this.finishedGameExists("Round of 128") || this.currentRoundExists("Round of 128").length >= 1 ?
                        <ExpansionPanel expanded={this.state.r128Open} onClick={() =>{this.setState({r128Open: this.state.r128Open ? false:true})}}>
                            <ExpansionPanelSummary style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Round of 128</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Round of 128")
                                }
                                {
                                    this.returnFinishedGames("Round of 128")
                                }
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null

                }

                {
                    this.finishedGameExists("Group Stage") || this.currentRoundExists("Group Stage").length >= 1 ?
                        <ExpansionPanel expanded={this.state.groupOpen} onClick={() =>{this.setState({groupOpen: this.state.groupOpen ? false:true})}}>
                            <ExpansionPanelSummary  style={{backgroundColor:"#5A82D8"}}
                                expandIcon={<ExpandMoreIcon style={{color:"white"}} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography  style={{width:"100%", color:"white"}} align="center" >Group Stage</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{backgroundColor:"#3968CC", flexDirection:"column"}}>
                                {
                                    this.returnCurrentRound("Group Stage")
                                }
                                {this.returnFinishedGames("Group Stage")}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        : null

                }
                </div>)




    }

    showLeagueMatches() {
        return(
            <GridList style={{display:"flex", justifyContent:"center", }}>
                {this.state.tables.map((item) => {
                    //console.log(item)
                    //document.body.style = 'background: #3968CC;';
                    return(
                        <Grid 
                        item 
                        style={{width:"2fr", height:"1fr"}}
                        //style={{maxWidth: this.state.width/2 < 500 ? this.state.width: this.state.width/2, width:"100%", flexDirection:"column",}}
                        >
                           {
                               /*<div>
                                <Typography align="center" style={{fontSize: this.state.width*0.06}} >{item.tableName}</Typography>
                            </div> */
                           }
                            
                            
                              <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                        numberOfGames={item.bestOf5or7} 
                        playerAScore={item.playerAScore} 
                        playerBScore={item.playerBScore} 
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament = {item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA= {item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName+"/"+item.PlayerA2LastName : item.PlayerAFirstName+"/"+item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName+", "+ item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB= {item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName+"/"+item.PlayerB2LastName : item.PlayerBFirstName+"/"+item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName+", "+ item.PlayerBFirstName : item.PlayerBFirstName}
                        tableName={item.tableName}
                        competitionRound={item.competitionRound}
                        editing={item.editing}
                        />  
                            
                        

                        </Grid >

                        
                    )
            
                })}
            </GridList>
        )
    }

    showSortByTable() {
        return(
<GridList style={{display:"flex", justifyContent:"center", }}>
                {this.state.tables.map((item) => {
                    //console.log(item)
                    //document.body.style = 'background: #3968CC;';
                    return(
                        <Grid 
                        item 
                        style={{width:"2fr", height:"1fr"}}
                        //style={{maxWidth: this.state.width/2 < 500 ? this.state.width: this.state.width/2, width:"100%", flexDirection:"column",}}
                        >
                           {
                               /*<div>
                                <Typography align="center" style={{fontSize: this.state.width*0.06}} >{item.tableName}</Typography>
                            </div> */
                           }
                            
                            
                              <OneTable  
                              key={item.gameid || null}  
                              gameid={item.gameid || null}
                        numberOfGames={item.bestOf5or7} 
                        playerAScore={item.playerAScore} 
                        playerBScore={item.playerBScore} 
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament = {item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA= {item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName+"/"+item.PlayerA2LastName : item.PlayerAFirstName+"/"+item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName+", "+ item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB= {item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName+"/"+item.PlayerB2LastName : item.PlayerBFirstName+"/"+item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName+", "+ item.PlayerBFirstName : item.PlayerBFirstName}
                        tableName={item.tableName}
                        competitionRound={item.competitionRound}
                        editing={item.editing}
                        />  
                            
                        

                        </Grid >

                        
                    )
                    

                    
                })}
            </GridList>

        )
    }
    

    state = {
        tables: [],
        width: window.innerWidth / 2 < 500 ? window.innerWidth : window.innerWidth / 2,
        height: window.innerHeight,
        sortBy: "competition",
        finalsOpen: true,
        semifinalsOpen: true,
        r128Open: true,
        r64Open: true,
        r32Open: true,
        r16Open: true,
        quarterfinalsOpen: true,
        groupOpen: true,
        competitionsList :["Group Stage", "Round of 128", "Round of 64", "Round of 32", "Round of 16", "Quarterfinals", "Semifinals", "Finals", "League Match"]




    }

    render() {
        //console.log("The State", this.state)
        return (
            <div style={{background:"#3968CC", width:"100%", height:"100%", backgroundSize:"fill" , flexGrow:1,  flex:1, }}>
                <Helmet >
                    <meta property="og:title" content={this.state.TournamentName+" | Live TT Scoreboard"}></meta>
                    <meta property="og:image" content="https://livettscoreboard.com/content/images/2019/09/IMG_1735.jpg" ></meta>
                    <meta property="og:description" content={"Watch the live results for the "+this.state.TournamentName} ></meta>
                    <meta property="og:url" content={"https://tournament.livettscoreboard.com/"+this.state.id} ></meta>
                    <meta property="og:type" content="website"></meta>
                </Helmet>
                <AppBar position="static" style={{ flexDirection: "row",backgroundColor:"#3968CC" }}>
                    <div style={{width:"20%"}}>
               <img src={logo}  style={{width:"100%", height:"100%", objectFit:"contain"}} />
                    </div>
                    <Typography align="center"  style={{ fontSize: this.state.width * 0.05, float: "left", width: "80%" }}>{this.state.TournamentName}</Typography>

                    

                </AppBar>

                {
                    /* 
                    
                    
                    */
                }
<div style={{ display: "inline-flex", justifyContent:this.state.isLeagueGame ? "center" : "flex-end", alignItems: "center", verticalAlign: "middle", width: "100%", flexDirection: "row", backgroundColor:"#5A82D8" }}>

    {
        this.state.isLeagueGame ? <Typography style={{color:"white", fontSize:24, }}>{this.state.TeamAWins +" - "+this.state.TeamAName+" vs "+this.state.TeamBName+" - "+this.state.TeamBWins}</Typography> 
        
        :<Typography style={{color:"white", marginRight:20 }}>Sort By:</Typography>}
                     
                     {
                       this.state.isLeagueGame ? null :  <Select 
                            value={this.state.sortBy}
                            onChange={(val => {
                                this.setState({ sortBy: val.target.value })
                                //console.log(this.state)
                            })}
                            style={{ backgroundColor: "white", marginRight:20}}
                            
                        >   
                            <MenuItem style={{marginLeft:5, marginRight:5}} value={"table"}><Typography style={{marginLeft:5, marginRight:5}}>Table</Typography></MenuItem>
                            <MenuItem style={{marginLeft:5, marginRight:5}} value={"competition"} ><Typography style={{marginLeft:5, marginRight:5}}>Round of Competition</Typography></MenuItem>
                        </Select>
                     }   
    
                        
                        
                    </div>
                    
                     {
                        this.state.initialLoadDone ? this.state.sortBy == "competition" ? this.isGameInCompetition() ? null : this.setState({sortBy: "table"}) : null : null
                     }

                    {
                        this.state.isLeagueGame ? this.showLeagueMatches() : this.state.sortBy == "competition" ?
                  this.showSortByRound():
                   null
                    }
                    {
                        this.state.isLeagueGame? this.returnFinishedLeagueGames() : this.state.sortBy == "table" ?
                    this.showSortByTable()
                    :null
                    }
             
                
              

                { /** 
            <GridList>
                {this.state.tables.map((item) => {
                    //console.log(item)
                    
                    return(
                        <Grid item style={{maxWidth: this.state.width/2 < 500 ? this.state.width: this.state.width/2, width:"100%", flexDirection:"column", height:this.state.width*0.3}}>
                            <div>
                                <Typography align="center" style={{fontSize: this.state.width*0.06}} >{item.tableName}</Typography>
                            </div>
                            
                              <OneTable  
                        numberOfGames={item.bestOf5or7} 
                        playerAScore={item.playerAScore} 
                        playerBScore={item.playerBScore} 
                        BScores={item.BScores ? item.BScores : []}
                        AScores={item.AScores ? item.AScores : []}
                        playerBMatchScore={item.playerBMatchScore}
                        playerAMatchScore={item.playerAMatchScore}
                        isTeamTournament = {item.isTeamTournament}
                        TeamAName={item.TeamAName}
                        TeamBName={item.TeamBName}
                        active={item.active}
                        playerA= {item.isDoubles ? item.PlayerALastName && item.PlayerA2LastName ? item.PlayerALastName+", "+item.PlayerA2LastName : item.PlayerAFirstName+", "+item.PlayerA2FirstName : item.PlayerALastName ? item.PlayerALastName+", "+ item.PlayerAFirstName : item.PlayerAFirstName}
                        playerB= {item.isDoubles ? item.PlayerBLastName && item.PlayerB2LastName ? item.PlayerBLastName+", "+item.PlayerB2LastName : item.PlayerBFirstName+", "+item.PlayerB2FirstName : item.PlayerBLastName ? item.PlayerBLastName+", "+ item.PlayerBFirstName : item.PlayerBFirstName}

                        />  
                            
                        

                        </Grid >

                        
                    )
                    

                    
                })}
            </GridList>
                */}

            </div>
        )
    }
}