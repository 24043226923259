import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import App from './App';
import React from 'react'
import WholeTournamentView from './wholeTournamentView';
import StreamScoreboard from './streamboard';
import HomeScreen from './homePage'
import Privacy from './privacy';
import TermsAndConditions from './termsandconditions';
import SignUp from './signup';
import StreamBoardV2 from './streamboardv2';
import StreamBoardV3 from './livestreamv3'
import StreamScoreboardCustom from './streamboardcustom';
import StreamScoreboardPreview from './streamboardpreview'
import LAPPCustom from './lapplivestream';
import LAPPCustomSL from './lapplivestreamstreamLabs'
import CustomizedScoreBoard  from './customizedScoreboard'
import CustomizedScoreBoardPreview  from './customizedScoreboardPreview'
import StaticHomeScreen from './statichome'
import TeamOnlyStreamScoreboard from './teamonlystreamboard'
import ElevenVRCustom from './elevenvr-test';
import VRStreamScoreboardCustom from './vrstreamboardcustom';
import MatchStats from './MatchStats';
import ETTUStream2022 from './ettustream2022';
const Main = () => {
    return (
        <Router >
            <Route strict exact path="/" component={HomeScreen} />

            <Route strict exact path="/tt/:staticurl/:table/scoreboard" component={CustomizedScoreBoard} />
            <Route strict exact path="/tt/:staticurl" component={StaticHomeScreen} />
            <Route strict exact path="/tt/:staticurl/:table/stream" component={StreamScoreboardCustom} />

            <Route strict exact path="/:tournamentID/:table/scoreboard" component={CustomizedScoreBoard }></Route>
            <Route exact strict  path="/:tournamentID" component={WholeTournamentView}></Route>
            <Route strict exact path="/:tournamentID/:table/scoreboardold" component={App}></Route>
            <Route strict exact path="/:tournamentID/:table/stream" component={StreamScoreboardCustom}></Route>
            <Route strict exact path="/:tournamentID/vr" component={VRStreamScoreboardCustom}></Route>
            <Route strict exact path="/terms/privacy" component={Privacy} ></Route>
            <Route strict exact path="/terms/termsandconditions" component={TermsAndConditions} ></Route>
            <Route strict exact path="/user/signup/" component={SignUp}></Route>
            <Route strict exact path="/:tournamentID/:table/streamv2" component={StreamBoardV3}></Route>

            <Route strict exact path="/:tournamentID/:table/streamv3" component={StreamBoardV3}></Route>
            <Route strict exact path="/:tournamentID/:table/streamcustom" component={StreamScoreboardCustom}></Route>
            <Route strict exact path="/:tournamentID/:table/ettu2022" component={ETTUStream2022}></Route>
            <Route strict exact path="/:tournamentID/:table/stream_teamonly" component={TeamOnlyStreamScoreboard}></Route>
            <Route strict exact path="/preview/:livestreamid" component={StreamScoreboardPreview}></Route>
            <Route strict exact path="/previewscoreboard/:scoreboardID" component={CustomizedScoreBoardPreview}></Route>
            <Route strict exact path="/:tournamentID/:table/lapingpong" component={LAPPCustom}></Route>
            <Route strict exact path="/:tournamentID/:table/lapingpongsl" component={LAPPCustomSL}></Route>
            <Route strict exact path="/:tournamentID/:table/stats" component={MatchStats}></Route>
        </Router>
    )
}

export default Main