import React from 'react'
import firebase from 'firebase';
import StreamV2LeagueScoreboard from './streamboardV2League'
import {Typography, Divider} from '@material-ui/core'

import TournamentName from './customlivestreamcomponents/custom_live_tournament_name'
import PlayerRow from './customlivestreamcomponents/custom_live_player_row'
export default class StreamBoardV3 extends React.Component {

  state = {
    AHasTimeOut: false,

    AServes: null,
    ATimeOutActive: null,
    AisServing: null,
    BTimeOutActive: null,
    ColorA: "",
    ColorB: "",
    PlayerA2FirstName: "",
    PlayerA2LastName: "",
    PlayerAFirstName: "",
    PlayerALastName: "",
    PlayerB2FirstName: "",
    PlayerB2LastName: "",
    PlayerBFirstName: "",
    PlayerBLastName: "",
    TeamBName: "",
    TournamentName: "",
    active: null,
    bestOf5or7: "",
    competitionRound: "",
    editing: false,
    isDoubles: null,
    isInternationalTournament: null,
    isTeamTournament: null,
    playerAMatchScore: 0,
    playerAScore: 0,
    playerBMatchScore: 0,
    playerBScore: 0,
    status: "inactive",
    switch: false,
    tableName: "Table 1",
    tournamentName: "",
    doneLoading: true,

    magnifier: 1,

    styles :{
        //Box Widths and Heights
        scoreboard_height: 150,
        scoreboard_width: 500,
        player_row_color_width : 15,
        player_row_color_height :30,
        player_row_height : 50,
        player_row_team_name_width: 115,
        player_row_player_name_width: 200,
        player_row_score_width: 40,
        player_row_white_paddle_height: 20,
        player_row_white_paddle_width: 24.225,

        //Fonts
        tournament_name_font_size: 30,
        name_font_size: 20,
        score_font_size: 30,
        table_name_and_round_font:12,
        font_color:"white",

      }
    
  }

  magnifySize(magnifier) {
    var newStyle={
        //Box Widths and Heights
        scoreboard_height: 150 * magnifier,
        scoreboard_width: (500* magnifier),
        player_row_color_width : 15* magnifier,
        player_row_color_height :30* magnifier,
        player_row_height : 50* magnifier,
        player_row_team_name_width: 115* magnifier,
        player_row_player_name_width: 200* magnifier,
        player_row_score_width: 40* magnifier,
        player_row_white_paddle_height: 20* magnifier,
        player_row_white_paddle_width: 24.225* magnifier,

        //Fonts
        tournament_name_font_size: 30* magnifier,
        name_font_size : 20 * magnifier,
        score_font_size: 30* magnifier,
        table_name_and_round_font:12* magnifier,
        font_color:"white",

      }
      this.setState({styles: newStyle})
  }

  componentWillMount() {
      
    //console.log(this.props.match.params)
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
      this.setState(val.val())
      this.setState({ doneLoading: true })
      console.log(val.val())
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TournamentName").on("value", (val) => {
      this.setState({ TournamentName: val.val() })
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAWins").on("value", (wins) => {
      if (typeof wins.val() != "undefined") {

        this.setState({ TeamAWins: wins.val() })
      }
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBWins").on("value", (wins) => {
      if (typeof wins.val() != "undefined") {
        this.setState({ TeamBWins: wins.val() })
      }
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAName").on("value", (wins) => {
      if (typeof wins.val() != "undefined") {
        console.log(wins.val())
        this.setState({ TeamANameLeague: wins.val() })
      }
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBName").on("value", (wins) => {
      if (typeof wins.val() != "undefined") {
        this.setState({ TeamBNameLeague: wins.val() })
      }
    })
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("magnify").on("value", (magnification) =>{
        console.log(magnification.val())
        if(typeof magnification.val() == "string" || typeof magnification.val() =="number" ) {
            this.magnifySize(magnification.val())
        }
        
    }
        
    )
  }

  
  

  render() {
    if (this.state.doneLoading) {
      return (
      <div style={{height: this.state.styles.scoreboard_height, width:this.state.styles.scoreboard_width, background:"radial-gradient(#5b7ef4,#98a2e6,#2d40d5)", margin:10, borderRadius:10}}>
          <TournamentName {...this.state} />
            <Divider ></Divider>
          <PlayerRow playerLetter="A" {...this.state} />
          <Divider ></Divider>
          <PlayerRow playerLetter="B" {...this.state} />
      </div>
      )
      
    }
    


  }
}