import React from 'react';
import logo from './logo.svg';
//import './App.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, Icon, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import firebase from 'firebase';

import backgroundImage from './blueBackground.jpg'
import serviceIcon from './white-paddle.svg';
import TimeOutIcon from './Timeout.svg'
import TimeOut from './timeout';
import FullScreen from 'react-full-screen';


export default class App extends React.Component {

  state = {
    height: window.innerHeight,
    width: window.innerWidth,
    timeout: false, 
    fullscreen: false,
    
    
    AServes: null,
    ATimeOutActive: null,
    AisServing: null,
    BTimeOutActive: null,
    ColorA: "",
    ColorB: "",
    PlayerA2FirstName: "",
    PlayerA2LastName: "",
    PlayerAFirstName: "",
    PlayerALastName: "",
    PlayerB2FirstName: "",
    PlayerB2LastName: "",
    PlayerBFirstName: "",
    PlayerBLastName: "",
    TeamBName: "",
    TeamAName: "",
    TournamentName: "",
    active: null,
    bestOf5or7: "",
    competitionRound: "",
    editing: false,
    isDoubles: null,
    isInternationalTournament: null,
    isTeamTournament: null,
    playerAMatchScore: 0,
    playerAScore: 0,
    playerBMatchScore: 0,
    playerBScore: 0,
    status: "inactive",
    switch: false,
    tableName: "Table 1",
    tournamentName: "",
    

  }

  updateDimensions() {
    if ( this.state.fullscreen) {
      //console.log("Something")
     
    }
    else{
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    
    
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${backgroundImage})`
    //console.log("Mounting the ");
    //console.log(this.props.match.params)
    firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
      //this.setState(val.val())
      
      firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TournamentName").on("value", (value) => {
        var newState = val.val()

        newState.tournamentName = value.val()
        console.log(newState)
        this.setState(newState)
      })
      //console.log(val.val())
  })
  
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));


  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }


  render() {


    return (

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      
      <FullScreen enabled={this.state.fullscreen}  onChange={(enabled) => { 
        
        this.setState({fullscreen: enabled, width: window.innerWidth, height: window.innerHeight})
      }}>

      <Grid container style={{ height: this.state.height, width: this.state.width, overflow:"hidden",
       backgroundImage: `url(${backgroundImage})`, 
      backgroundSize: 'cover', }} >
        {
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //////////////////////////////////////////////////////  Top bar   /////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        }

        <Typography>
          <Typography align="center" noWrap style={{ fontSize: "6vw", width: this.state.width, height: this.state.width * 0.075, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>{this.state.tournamentName}</Typography>

          {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // Second Line, Team A, Stage, and Team B
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          }
          <div style={{ display: "table", }}>
            <div style={{ float: "left", width: this.state.width / 3, }}>
              <Typography align="center" noWrap style={{ fontSize: "3vw", width: this.state.width / 3, height: this.state.width * 0.04, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>{this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.TeamBName: this.state.TeamAName: this.state.switch ? this.state.TeamAName: this.state.TeamBName}</Typography>

            </div>
            <div style={{ float: "left", width: this.state.width / 3, }}>
              <Typography align="center" noWrap style={{ fontSize: "3vw", width: this.state.width / 3, height: this.state.width * 0.04, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>{this.state.editing ? "--" :this.state.competitionRound}</Typography>

            </div>
            <div style={{ float: "left", width: this.state.width / 3, }}>
              <Typography align="center" noWrap style={{ fontSize: "3vw", width: this.state.width / 3, height: this.state.width * 0.04, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>{this.state.editing ? "--" :!this.state.scorboardDirectionAway ? this.state.switch ?   this.state.TeamAName: this.state.TeamBName :this.state.switch ? this.state.TeamBName: this.state.TeamAName}</Typography>

            </div>

          </div>
        </Typography>

        {
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // Player A Section
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        }

        <Grid item style={{ flexGrow: 1, width: this.state.width / 2, height: this.state.height * 0.75, }} >
          <Grid item >
            {
              //Player Names and Match Score
            }
            <div style={{ display: "table" }}>
              <div style={{ float: "left", width: this.state.width * 0.4 }}>
                <Typography align="center" style={{ fontSize: "6vw", height: this.state.width * 0.06, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>
                  {this.state.editing ? "--" : !this.state.scorboardDirectionAway ?  this.state.switch ? this.state.isDoubles? this.state.PlayerBLastName.length >=1 ? this.state.PlayerBLastName: this.state.PlayerBFirstName: this.state.PlayerBLastName : this.state.isDoubles? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName: this.state.PlayerALastName : this.state.switch ? this.state.isDoubles? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName: this.state.PlayerALastName: this.state.isDoubles? this.state.PlayerBLastName.length >=1 ? this.state.PlayerBLastName: this.state.PlayerBFirstName: this.state.PlayerBLastName}
                  </Typography>
                <Typography align="center" style={{ fontSize: "6vw", height: this.state.width * 0.08, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>
                  {this.state.editing ? "--" :!this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ?  (this.state.PlayerB2LastName.length >=1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName: this.state.isDoubles ? this.state.PlayerA2LastName.length>= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName :this.state.switch ? this.state.isDoubles ? this.state.PlayerA2LastName.length>= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName : this.state.isDoubles ?  (this.state.PlayerB2LastName.length >=1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName}
                  </Typography>
              </div>
              <div style={{ float: "left", width: this.state.width * 0.08, }}>
                <Paper style={{ backgroundColor: "white", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", width: this.state.width * 0.08 }}>
                  <Typography style={{ fontSize: "10vw", textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerBMatchScore : this.state.playerAMatchScore : this.state.switch ? this.state.playerAMatchScore : this.state.playerBMatchScore }</Typography>
                </Paper >
              </div>
            </div>

            {
              // Player Score of the current game
            }
            <div style={{ display: "table" }} >

              <div style={{ paddingLeft: this.state.width * 0.05, float: "left" }}>
                <Paper alignItems="center" justify="center" style={{ backgroundColor: "white", display: "inline-flex", justifyContent: "center", alignItems: "center", width: this.state.width * 0.3, verticalAlign: "middle", height: this.state.width * 0.22 }}>
                  <Typography style={{ fontSize: "20vw", textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerBScore: this.state.playerAScore :this.state.switch? this.state.playerAScore: this.state.playerBScore}</Typography>
                </Paper>
              </div>
              <div style={{ float: "left", width: this.state.width * 0.14, }}>
                {
                  !this.state.scorboardDirectionAway ?
                  this.state.switch? !this.state.AisServing ?  <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> : 
                  this.state.AisServing ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex"  }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
                  :
                  this.state.switch ? this.state.AisServing? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img  src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.AisServing ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div>
            
                }
                {
                  !this.state.scorboardDirectionAway ?
                  this.state.switch ? this.state.BTimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div>: <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.ATimeOutActive ?  <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
                  :
                  this.state.switch? this.state.ATimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.BTimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
              

                }
                
              </div>

            </div>




          </Grid>


          {
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //Player B Side
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          }
        </Grid>
        <Grid item style={{ flexGrow: 1, width: this.state.width / 2, height: this.state.height * 0.75 }} >
          <Grid item  >

            {
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              // Player B Name and Match Score
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            }
            <div style={{ display: "table" }}>

              <div style={{ float: "left", width: this.state.width * 0.08, paddingLeft: this.state.width * 0.019 }}>
                <Paper style={{ backgroundColor: "white", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", width: this.state.width * 0.08 }}>
                  <Typography style={{ fontSize: "10vw" }}>{!this.state.scorboardDirectionAway ? this.state.switch ? this.state.playerAMatchScore : this.state.playerBMatchScore: this.state.switch ? this.state.playerBMatchScore : this.state.playerAMatchScore}</Typography>
                </Paper >
              </div>
              <div style={{ float: "left", width: this.state.width * 0.4 }}>
                <Typography align="center" style={{ fontSize: "6vw", height: this.state.width * 0.06, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>
                  {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName: this.state.PlayerALastName: this.state.isDoubles? this.state.PlayerBLastName.length >=1 ? this.state.PlayerBLastName: this.state.PlayerBFirstName: this.state.PlayerBLastName: this.state.switch ? this.state.isDoubles? this.state.PlayerBLastName.length >=1 ? this.state.PlayerBLastName: this.state.PlayerBFirstName: this.state.PlayerBLastName : this.state.isDoubles? this.state.PlayerALastName.length >= 1 ? this.state.PlayerALastName : this.state.PlayerAFirstName: this.state.PlayerALastName }
                  </Typography>
                <Typography align="center" style={{ fontSize: "6vw", height: this.state.width * 0.08, textShadow: "-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white", color:"white" }}>
                  {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.isDoubles ? this.state.PlayerA2LastName.length>= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName : this.state.isDoubles ?  (this.state.PlayerB2LastName.length >=1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName: this.state.switch ? this.state.isDoubles ?  (this.state.PlayerB2LastName.length >=1 ? this.state.PlayerB2LastName : this.state.PlayerB2FirstName) : this.state.PlayerBFirstName: this.state.isDoubles ? this.state.PlayerA2LastName.length>= 1 ? this.state.PlayerA2LastName : this.state.PlayerA2FirstName : this.state.PlayerAFirstName }
                  </Typography>
              </div>

            </div>

            {
              //Player B Current Game Score                    {this.state.editing ? "--" : !this.state.scorboardDirectionAway ? this.state.switch ? this.state.PlayerALastName: this.state.PlayerBLastName: this.state.switch ? this.state.PlayerBLastName : this.state.PlayerALastName }

            }

            <div style={{ display: "table" }} >
              <div style={{ float: "left", width: this.state.width * 0.14 }}>
                {
                  !this.state.scorboardDirectionAway ? 
                  this.state.switch ? this.state.AisServing? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img  src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.AisServing ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div>
                  :
                  this.state.switch? !this.state.AisServing ?  <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> : 
                  this.state.AisServing ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex"  }}><img src={serviceIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
             
                }
                { 
                  !this.state.scorboardDirectionAway ?
                  this.state.switch? this.state.ATimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.BTimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
                  :
                  this.state.switch ? this.state.BTimeOutActive ? <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div>: <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div> :
                  this.state.ATimeOutActive ?  <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14, justifyContent:"center", alignItems:"center", display:"flex" }}><img src={TimeOutIcon} height={this.state.width * 0.09} width={this.state.width * 0.09} /></div> : <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
                
                }
                <div style={{height:this.state.width * 0.12, width:this.state.width * 0.14}}></div>
              </div>
              <div style={{ paddingRight: this.state.width * 0.05, alignItems: "flex-end", float: "left" }}>
                <Paper alignItems="center" justify="center" style={{ backgroundColor: "white", display: "inline-flex", justifyContent: "center", alignItems: "center", width: this.state.width * 0.3, verticalAlign: "middle", height: this.state.width * 0.22 }}>
                  <Typography style={{ fontSize: "20vw", }}>{ !this.state.scorboardDirectionAway ? this.state.switch? this.state.playerAScore: this.state.playerBScore: this.state.switch ? this.state.playerBScore: this.state.playerAScore}</Typography>


                </Paper>

              </div>
            </div>

          </Grid>
        </Grid>
        {
        this.state.BTimeOutActive ? <TimeOut name={this.state.PlayerBLastName} width={this.state.width}  /> : null
        
        }
        {
          this.state.ATimeOutActive ? <TimeOut name={this.state.PlayerALastName} width={this.state.width}  /> : null
        }
        <Button
        style={{position:"absolute", bottom:0, left:0, width:window.innerWidth, height:window.innerHeight}}
         onClick={() => {this.setState({fullscreen: this.state.fullscreen ? false : true, width: window.innerWidth, height: window.innerHeight})}}></Button>
      </Grid>
      </FullScreen>
    );

  }
}

/**
function App() {

  return (
    <div  >

    </div>
  );
}

export default App;
*/