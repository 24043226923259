import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import firebase from 'firebase';

import servicePhoto from './paddle.svg'




export default class StreamScoreboard extends React.Component {

    state ={
        AHasTimeOut: false,

AServes: null,
ATimeOutActive: null,
AisServing: null,
BTimeOutActive: null,
ColorA: "",
ColorB: "",
PlayerA2FirstName: "",
PlayerA2LastName: "",
PlayerAFirstName: "",
PlayerALastName: "",
PlayerB2FirstName: "",
PlayerB2LastName: "",
PlayerBFirstName: "",
PlayerBLastName: "",
TeamBName: "",
TournamentName: "",
active: null,
bestOf5or7: "",
competitionRound: "",
editing: false,
isDoubles: null,
isInternationalTournament: null,
isTeamTournament: null,
playerAMatchScore: 0,
playerAScore: 0,
playerBMatchScore: 0,
playerBScore: 0,
status: "inactive",
switch: false,
tableName: "Table 1",
tournamentName: "",

    }

    componentWillMount() {
        //console.log(this.props.match.params)
        
        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).on("value", (val) => {
            this.setState(val.val())
            
            //console.log(val.val())
        })
        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamAWins").on("value", (wins) =>{
            if(typeof wins.val() != "undefined") {
            
                this.setState({TeamAWins: wins.val()})
            }
        })
        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("TeamBWins").on("value", (wins) =>{
            if(typeof wins.val() != "undefined") {
                this.setState({TeamBWins: wins.val()})
            }
        })
        firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).once("value", (val) => {
            this.setState({TournamentName: val.val().TournamentName})
        })
    }

    componentDidMount() {
        
        
    }
    

    render() {
        console.log(this.state)
        return (
            <div  >
                {
                    /*
<Paper  style={{float:"left", width:this.state.isTeamTournament? 700 :475, height:50,backgroundColor:"navy", margin:5, marginLeft:20, justifyContent:"center", alignItems:"center", transform:"skew(-20deg)"}}>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.TournamentName}</Typography>
                    <Typography align="center" noWrap style={{width:"100%", fontSize:18, color:"white"}}>{this.state.tableName} - {this.state.competitionRound}</Typography>
                    </Paper>

                    */
                }
                 
                   
                
                <div style={{display:"table"}}>
                    <Paper style={{float:"left", width:20, height:60, backgroundColor:this.state.ColorA, margin:5, marginLeft:20, transform:"skew(-20deg)"}}>
                    </Paper>
                    {

                        //Show the flag if true
                        this.state.isInternationalTournament ? 
                        <Paper style={{float:"left", width:100, height:60, backgroundColor:"navy",margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{color:"white"}}>F</Typography>
                    </Paper> 
                    :
                    null
                    }
                    {
                        this.state.isLeagueGame? <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.TeamAWins}</Typography>
                    </Paper>
                    :null
                    }
                    {
                        this.state.isTeamTournament ? <Paper style={{float:"left", width:200, height:60,  display: "inline-flex",backgroundColor:"navy", alignItems:"center",verticalAlign:"middle", paddingLeft:10, margin:5,transform:"skew(-20deg)", }}>
                        <Typography noWrap style={{fontSize: 32,color:"white"}}>{this.state.editing ? "--" :this.state.TeamAName}</Typography>
                    </Paper> : null
                    }
                    
                    <Paper style={{float:"left", width:300, height:60,  display: "inline-flex",backgroundColor:"navy", alignItems:"center",verticalAlign:"middle", paddingLeft:10, margin:5,transform:"skew(-20deg)"}}>
                        <Typography noWrap style={{fontSize: 32,color:"white"}}>{this.state.editing ? "--" :this.state.isDoubles ? (this.state.PlayerALastName.length >= 1 && this.state.PlayerA2LastName.length >= 1) ? this.state.PlayerALastName+"/"+this.state.PlayerA2LastName : this.state.PlayerAFirstName+"/"+this.state.PlayerA2FirstName :this.state.PlayerAFirstName+" "+this.state.PlayerALastName}</Typography>
                    </Paper>
                    <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.playerAMatchScore}</Typography>
                    </Paper>
                    <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.playerAScore}</Typography>
                    </Paper>
                    {
                        this.state.AisServing || this.state.ATimeOutActive ? 
                        <div style={{float:"left", width:80, height:60, display: "inline-flex", alignItems:"center",verticalAlign:"middle", margin:5,}}>

                        <img height={50} width={40} src={servicePhoto} />
                        </div>
                    
                    : null
                    }
                    
                    
                </div>
                <div style={{display:"table"}}>
                    <Paper style={{float:"left", width:20, height:60, backgroundColor:this.state.ColorB, margin:5 ,transform:"skew(-20deg)", marginLeft:20, outlineColor:"white", outlineWidth:"6px" }}>
                    </Paper>

                    { /*
                        this.state.isInternationalTournament ? <Paper style={{float:"left", width:100, height:100, backgroundColor:"navy",margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{color:"white"}}>F</Typography>
                    </Paper>: null
                    */
                    }
                    {
                        this.state.isLeagueGame? <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.TeamBWins}</Typography>
                    </Paper>
                    :null
                    }
                    
                    {this.state.isTeamTournament ? <Paper style={{float:"left", width:200, height:60,  display: "inline-flex",backgroundColor:"navy", alignItems:"center",verticalAlign:"middle", paddingLeft:10, margin:5,transform:"skew(-20deg)"}}>
                        <Typography noWrap style={{fontSize: 32,color:"white"}}>{this.state.editing ? "--" :this.state.TeamBName}</Typography>
                    </Paper> : null
                    }
                    
                    <Paper style={{float:"left", width:300, height:60,  display: "inline-flex",backgroundColor:"navy", alignItems:"center",verticalAlign:"middle", paddingLeft:10, margin:5,transform:"skew(-20deg)"}}>
                        <Typography noWrap style={{fontSize: 32,color:"white"}}>{this.state.editing ? "--" :this.state.isDoubles ? (this.state.PlayerBLastName.length >= 1 && this.state.PlayerB2LastName.length >= 1) ? this.state.PlayerBLastName+"/"+this.state.PlayerB2LastName : this.state.PlayerBFirstName+"/"+this.state.PlayerB2FirstName :this.state.PlayerBFirstName+" "+this.state.PlayerBLastName}</Typography>
                    </Paper>
                    <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.playerBMatchScore}</Typography>
                    </Paper>
                    <Paper style={{float:"left", width:50, height:60, display: "inline-flex",backgroundColor:"navy", justifyContent:"center", alignItems:"center",verticalAlign:"middle", margin:5,transform:"skew(-20deg)"}}>
                        <Typography style={{fontSize: 32,color:"white"}}>{this.state.playerBScore}</Typography>
                    </Paper>
                    {
                        !this.state.AisServing || this.state.BTimeOutActive ?
                        <div style={{float:"left", width:80, height:60, display: "inline-flex", alignItems:"center",verticalAlign:"middle", margin:5,}}>

                        <img height={50} width={40} src={servicePhoto} />
                        </div>
                    :null
                    }
                    
                    
                </div>
                
                
                
            </div>


            
        )
    }
}