import React from 'react';
import {Typography} from '@material-ui/core';

/**
 * 
 * @param {*} props 
 * @param {*} props.TournamentName The name of the tournament or league match
 * @param {string} props.tableName The table name. Shown if its a tournament.
 * @param {string} props.competitionRound The round of competition, shown if its a tournament.
 * @param {boolean} props.isLeagueGame True if it is a league game, false if its a tournament game.
 * @param {object} props.styles.font_color 
 * @param {object} props.styles.tournament_name_font_size
 * @param {string} props.table_name_and_round_font
 */
const TounamentName = (props) => {
    
    
    return(
        <div style={{width:"100%"}}>
        <Typography align="center" noWrap style={{
            width: "100%", 
            color:props.styles.font_color, 
            fontSize: props.styles.tournament_name_font_size}}>{props.TournamentName}</Typography>
        {
            /*
        props.isLeagueGame? null:
        <Typography align="center" noWrap style={{
            width: "100%", 
            color:props.styles.font_color, 
        fontSize:props.styles.table_name_and_round_font}}>{props.tableName} - {props.competitionRound}</Typography>
        */
    }    
        </div>
    )
}
export default TounamentName