import React from 'react'
import firebase from 'firebase';
import StreamV2LeagueScoreboard from './streamboardV2League'

export default class MatchStats extends React.Component {
   
      state = {
        doneLoading:false,
        AHasTimeOut: false,

        AServes: null,
        ATimeOutActive: null,
        AisServing: null,
        BTimeOutActive: null,
        ColorA: "",
        ColorB: "",
        PlayerA2FirstName: "",
        PlayerA2LastName: "",
        PlayerAFirstName: "",
        PlayerALastName: "",
        PlayerB2FirstName: "",
        PlayerB2LastName: "",
        PlayerBFirstName: "",
        PlayerBLastName: "",
        TeamBName: "",
        TournamentName: "",
        active: null,
        bestOf5or7: "",
        competitionRound: "",
        editing: false,
        isDoubles: null,
        isInternationalTournament: null,
        isTeamTournament: null,
        playerAMatchScore: 0,
        playerAScore: 0,
        playerBMatchScore: 0,
        playerBScore: 0,
        status: "inactive",
        switch: false,
        tableName: "Table 1",
        tournamentName: "",


      }

    componentWillMount() {


        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerAFirstName").on("value", (val) => {
            this.setState({PlayerAFirstName: val.val()})
        })

        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerALastName").on("value", (val) => {
            this.setState({PlayerALastName: val.val()})
        })


        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerAImageURL").on("value", (val) => {
            this.setState({PlayerAImageURL: val.val()})
        })

        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerBFirstName").on("value", (val) => {
            this.setState({PlayerBFirstName: val.val()})
        })

        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerBLastName").on("value", (val) => {
            this.setState({PlayerBLastName: val.val()})
        })

        firebase.database().ref("tournaments").
        child(this.props.match.params.tournamentID).
        child("tables")
        .child(this.props.match.params.table).
        child("PlayerBImageURL").on("value", (val) => {
            this.setState({PlayerBImageURL: val.val()})
        })



            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).once("value",(val)=>{console.log(val.val())})

            firebase.database().ref("tournaments").child(this.props.match.params.tournamentID).child("tables").child(this.props.match.params.table).child("gameid").on("value", (val) => {
              //this.setState(val.val())
              
              let gameid = val.val()

                firebase.database().ref("stats").child(gameid).child("gameStats").on("value", (val) =>{
                  console.log(val.val())
                  this.setState({gameStats: val.val(), hasGameStats: val.val() ? true : false, doneLoading:true})
              })  
            })

    }

    cellStyles = {
        textAlign: "center",
        borderBottom: "2px solid white",
    }

    render() {
        if (this.state.doneLoading) {
            console.log(this.state)
            return (<div style={{ display: "flex" }} >
                <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", flexWrap: "nowrap" }}>
<div style={{
                        backgroundColor: "black",
                        color: "white", textAlign: "center",
                        width: "fit-content", fontSize: 32,
                        fontWeight: "bold",
                        borderRadius: 5, padding: 5
                    }}>
                        Match Stats
                    </div>
                   
                    <div style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", position: "relative", borderRadius: 5 }}>
                        <img height={"auto"} width={300} src={this.state.PlayerAImageURL}></img>
                        <img height={"auto"} width={300} src={this.state.PlayerBImageURL}></img>
                         {
                        this.state.PlayerAImageURL.length > 0 && this.state.PlayerBImageURL.length>0 ?

                      <div style={{
                            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 45, backgroundColor: "black", borderRadius: 5, padding: 5,
                            fontWeight: "bold",
                            WebkitTextStroke: "2px white"
                        }}>VS</div>  
                    
                : null
                }
                        
                    </div>
                {
                    this.state.gameStats ? 

<div style={{ width: "75%", position: "relative" }} >
                        <table cellPadding={4} style={{ color: "white", position: "absolute", top: "50%", width: "75%", left: "50%", transform: "translate(-50%, 0)", background: "linear-gradient(90deg, rgba(0,0,0,0.5032606792717087) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0.5004595588235294) 100%)" }}>
                            <tbody >
                            <tr style={{ padding: 5 }} >
                                    <th colSpan={3} style={{...this.cellStyles, fontSize:36,}}>
                                    <div style={{width:"50%", float:"left"}}>{this.state.PlayerAFirstName} {this.state.PlayerALastName}</div>
                                    <div style={{width:"50%", float:"right"}}>{this.state.PlayerBFirstName} {this.state.PlayerBLastName}</div></th>
                                    
                                    </tr>
                                <tr style={{ padding: 5 }} >
                                    <th style={this.cellStyles}>Total</th>
                                    {/* {<th style={this.cellStyles}>Current</th>} */}
                                    <th style={this.cellStyles}>Game {this.state.gameStats && this.state.gameStats.gameList ? this.state.gameStats.gameList.length +1 : 1 } </th>
                                    {/* <th style={this.cellStyles}>Current</th> */}
                                    <th style={this.cellStyles}>Total</th>
                                </tr>

                                <tr>
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerA.totalPointsWon}</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerA.totalPointsWon}</td> */}
                                    <td style={{ ...this.cellStyles, fontWeight: "bold" }}>Points</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerB.totalPointsWon}</td> */}
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerB.totalPointsWon}</td>
                                </tr>
                                <tr>
                                    <td style={this.cellStyles}>{Math.round(this.state.gameStats.total.percentage.playerA.percentTotalPointsWon*100)+"%"}</td>
                                    {/* <td style={this.cellStyles}>{Math.round(this.state.gameStats.current.percentages.playerA.percentTotalPointsWon*100)+"%"}</td> */}
                                    <td style={{ ...this.cellStyles, fontWeight: "bold" }}>Points %</td>
                                    {/* <td style={this.cellStyles}>{Math.round(this.state.gameStats.current.percentages.playerB.percentTotalPointsWon*100)+"%"}</td> */}
                                    <td style={this.cellStyles}>{Math.round(this.state.gameStats.total.percentage.playerB.percentTotalPointsWon*100)+"%"}</td>
                                </tr>
                                <tr>
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerA.pointsWonOnServe}</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerA.pointsWonOnServe}</td> */}
                                    <td style={{ ...this.cellStyles, fontWeight: "bold" }}>Points On Serve</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerB.pointsWonOnServe}</td> */}
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerB.pointsWonOnServe}</td>
                                </tr>

                                <tr>
                                    <td style={this.cellStyles}>{Math.round(this.state.gameStats.total.percentage.playerA.percentPointsWonOnServe*100)+"%"}</td>
                                    {/* <td style={this.cellStyles}>{Math.round(this.state.gameStats.current.percentages.playerA.percentPointsWonOnServe*100)+"%"}</td> */}
                                    <td style={{ ...this.cellStyles, fontWeight: "bold" }}>Points On Serve %</td>
                                    {/* <td style={this.cellStyles}>{Math.round(this.state.gameStats.current.percentages.playerB.percentPointsWonOnServe*100)+"%"}</td> */}
                                    <td style={this.cellStyles}>{Math.round(this.state.gameStats.total.percentage.playerB.percentPointsWonOnServe*100)+"%"}</td>
                                </tr>
                                <tr>
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerA.longestWinningStreak}</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerA.longestWinningStreak}</td> */}
                                    <td style={{ ...this.cellStyles, fontWeight: "bold" }}>Longest Streak</td>
                                    {/* <td style={this.cellStyles}>{this.state.gameStats.current.playerB.longestWinningStreak}</td> */}
                                    <td style={this.cellStyles}>{this.state.gameStats.total.playerB.longestWinningStreak}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    : null
                }
                    



                </div>


            </div>)
        }
        else {
            return (
                <div></div>
            )
        }


    }
}