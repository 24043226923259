import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import blackLinear from './images/blacklinear.png';
import firebase from 'firebase';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://livettscoreboard.com">
        Live TT Scoreboard
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const  [password, setPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [ showSuccess, setShowSuccess] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(false)

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={blackLinear} style={{maxHeight:"auto", maxWidth:"90%"}} />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(val) =>{
                    //console.log(val.target.value)
                    setFirstName(val.target.value)
                }}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(val) =>{
                    //console.log(val.target.value)
                    setLastName(val.target.value)
                }}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
              onChange={(val) =>{
                //console.log(val.target.value)
                setEmail(val.target.value)
            }}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
              onChange={(val) =>{
                //console.log(val.target.value)
                setPassword(val.target.value)
            }}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" checked={termsAndConditions} onClick={() =>{
                    termsAndConditions ? setTermsAndConditions(false) : setTermsAndConditions(true)
                }} />}
                //label="I want to receive inspiration, marketing promotions and updates via email."
                label={
                    <Typography>
                        By checking this box, you agree to our <a target="_blank" href="https://tournament.livettscoreboard.com/terms/termsandconditions">Terms And Conditions</a> and <a target="_blank" href="https://tournament.livettscoreboard.com/terms/privacy">Privacy Policy</a>
                    </Typography>
                     
                }
              >
                 
              </FormControlLabel>
            </Grid>
          </Grid>
          <Button
            onClick={() => {
                firebase.auth().createUserWithEmailAndPassword(email, password).then(
                    (val) => {
                        val.user.updateProfile({displayName: firstName+" "+lastName})
                        try {
                           val.user.sendEmailVerification() 
                        }
                        catch(err) {
                            console.log(err)
                        }
                        setShowSuccess(true)
                    }
                ).catch((err) => {
                    console.log(err)
                    setError(err.message)
                })
            }}
            disabled={!termsAndConditions}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          {
              error.length > 0 ?
              <Typography style={{color:"red"}}>{error}</Typography> :
              null
          }
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="https://livettscoreboard.com/mobile-application-downloads/" variant="body2">
                Already have an account? Download the Mobile App and Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      {
          showSuccess ?
          <Dialog open={showSuccess}>
              <DialogTitle>
                Registration is successful!
              </DialogTitle>
              <DialogContent>
                  An email has been sent to confirm your email account!
                  After verifying, the next step is to download the mobile application and log in!
              </DialogContent>
              <DialogActions>
                  <Button href="https://livettscoreboard.com/mobile-application-downloads/"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  >Download</Button>
              </DialogActions>
          </Dialog>
           : null
      }
    </Container>
  );
}