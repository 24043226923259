import React from 'react';
import firebase from 'firebase';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import logo from "./Framelinearlogo.png";
import appleLogo from './apple.png'
import playstore from './playstore.png'
import { Paper, Card, CardContent, CardHeader, CardActionArea } from '@material-ui/core';


export default class SingleTournament extends React.Component {

    state = {
        expanded: false
    }

    componentDidMount(){
        if(this.props.expanded){
            this.setState({expanded: true})
        }
    }

    render() {
        return (
            <Card style={{margin:5}} >

                {
                    //                                            <div style={{ width: "100%", flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                    //</div>
                }
                <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", margin: 5,  }}>
                    <Typography align="center"  style={{ fontSize: "32", width:"100%" }}>{this.props.data.TournamentName}</Typography>
                
                </div>
                {
                    this.props.data.isLeagueGame ? 
                    <div>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        {this.props.data.TeamAName} vs {this.props.data.TeamBName}
                    </Typography>
                </div>:
                null
                }
                <div>
                    <Typography align="center" style={{ fontSize: 20 }}>
                        Date: {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][new Date(this.props.data.startDate).getMonth()] + " " + new Date(this.props.data.startDate).getDate().toString() + ", " + new Date(this.props.data.startDate).getFullYear().toString() + ", " + ((new Date(this.props.data.startDate).getHours() + 11) % 12 + 1).toString() + ":" + (new Date(this.props.data.startDate).getMinutes() > 9 ? new Date(this.props.data.startDate).getMinutes() : "0" + new Date(this.props.data.startDate).getMinutes()) + " " + (new Date(this.props.data.startDate).getHours() > 12 ? "PM" : "AM")}
                    </Typography>
                </div>
                <CardActionArea style={{borderTopColor:"gray" , borderTopWidth:1, borderTopStyle:"solid", borderBottomColor:"gray" , borderBottomWidth:1, borderBottomStyle:"solid"}} href={"/" + this.props.data.id}  >
               <Typography align="center" style={{width:"100%", fontSize:"20", }}>View Tournament</Typography>

                </CardActionArea>
                <CardActionArea>
                    <div style={{alignItems:"center", display:"flex", flexDirection:"row", backgroundColor:"whitesmoke" }}
                     onClick={() => {
                        this.setState({ expanded: this.state.expanded ? false : true })
                    }}>
                        {this.state.expanded ? <KeyboardArrowDownIcon />: <KeyboardArrowRightIcon />}

                       <Typography style={{alignItems:"center", fontSize:"20"}}>   Expand To See Individual Tables</Typography>
                    </div>
                </CardActionArea>
                {
                    this.state.expanded ? <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent:"center", alignItems:"center", }}>
                        {
                            this.props.data.tables.map((table, index) => {
                                return (
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", borderBottom:"1px gray solid", margin: 5 }}>
                                        <Typography style={{ fontSize: "20" }}>{table.tableName}</Typography>
                                        <div style={{ flexDirection: "row" }}>
                                            {
                                                this.props.isStatic ? 
                                                <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={window.location.toString()+ "/" + index + "/scoreboard"}>Scoreboard</Button>
                                                
                                                : <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={"/" + this.props.data.id + "/" + index + "/scoreboard"}>Scoreboard</Button>

                                            }
                                            {
                                                this.props.isStatic ? 
                                                <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={window.location.toString()+ "/" + index + "/stream"+(this.props.data.defaultLiveStreamScoreboard =="v2" ? "v2":"")}>Live Stream</Button>

                                                : 
                                                <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={"/" + this.props.data.id + "/" + index + "/stream"+(this.props.data.defaultLiveStreamScoreboard =="v2" ? "v2":"")}>Live Stream</Button>

                                            }
                                            {
                                                this.props.data.isLeagueGame ?
                                                this.props.isStatic  ? 
                                                <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={window.location.toString()+ "/" + index + "/stream_teamonly"}>Team Only</Button>

                                                : 
                                                <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: "20", color: "white" }} href={"/" + this.props.data.id + "/" + index + "/stream_teamonly"}>Team Only</Button>
                                                :null
                                            }
                                           
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div> : null
                }
                { /*}
                <CardActionArea>

                    <ExpansionPanel key={this.props.data.TournamentName + this.props.data.id} style={{ width: "100%" }}>
                        {
                            /* 
                            
                            <ExpansionPanelSummary style={{ justifyContent: "center", display: "flex", alignItems: "center" }}
                            expandIcon={<ExpandMoreIcon />}
                            
                            aria-controls="panel1c-content"
                            id="panel1c-header"

                        >
                            <div style={{ width: "100%", height: "100%", flexDirection: "row", display: "flex", justifyContent: "space-between" }}>
                                <Typography align="center" style={{ fontSize: this.state.width * 0.025, }}>{this.props.data.TournamentName}</Typography>
                                <Button style={{ backgroundColor: "#3968CC" }} href={"/" + this.props.data.id}><Typography style={{ fontSize: this.state.width * 0.017, color: "white" }}>View Tournament</Typography></Button>
                            </div>

                        </ExpansionPanelSummary>
                            
                            
                        }


                        <ExpansionPanelDetails >
                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                {
                                    this.props.data.tables.map((table, index) => {
                                        return (
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid black", borderRadius: 10, margin: 5 }}>
                                                <Typography style={{ fontSize: this.state.width * 0.04 }}>{table.tableName}</Typography>
                                                <div style={{ flexDirection: "row" }}>
                                                    <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: this.state.width * 0.02, color: "white" }} href={"/" + this.props.data.id + "/" + index + "/scoreboard"}>Scoreboard</Button>
                                                    <Button style={{ backgroundColor: "#3968CC", margin: 5, fontSize: this.state.width * 0.02, color: "white" }} href={"/" + this.props.data.id + "/" + index + "/stream"}>Live Stream</Button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                </CardActionArea>
               */ }

            </Card>
        )
    }
}