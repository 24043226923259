import React from 'react';
import firebase from 'firebase'
import { Paper, Typography, Grid, Table, } from '@material-ui/core';
import { height } from '@material-ui/system';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LiveStats from './LiveStats';



export default class OneTable extends React.Component {

    updateDimensions() {
        this.setState({ width: window.innerWidth / 2 < 500 ? window.innerWidth : window.innerWidth / 2, height: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if(this.props.gameid){
          firebase.database().ref("stats").child(this.props.gameid).child("gameStats").on("value", (val) =>{
            console.log(val.val())
            this.setState({gameStats: val.val(), hasGameStats: val.val() ? true : false})
        })  
        }
        


    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))
        //console.log("New Table", this.props)
    }

    state = {
        width: window.innerWidth / 2 < 500 ? window.innerWidth : window.innerWidth / 2,
        height: window.innerHeight
    }

    loadStats(){
        try{
          return  <LiveStats {...this.state.gameStats} />
        }
        catch(err){
            return null
        }
        
    }

    render() {
        //console.log(this.props)

        return (

            
            
            <Paper style={{margin:5, width:this.state.width-10}}>
                <Typography align="center" style={{width:"100%"}}>{this.props.tableName}{this.props.competitionRound.length > 1 ? " - "+this.props.competitionRound: null }</Typography>
                <Table  size="small" style={{tableLayout:"fixed"}}>
                    {
                        /* 
                        <colgroup style={{display:"grid"}}>
                    {
                        this.props.isTeamTournament ?<col width={"25fr"} />: null
                    }
                    
                    <col width={this.props.isTeamTournament? "25fr":"50fr"} />
                    <col width={this.props.numberOfGames == 7 ? "5.7fr": "8fr"} />
                    <col width={this.props.numberOfGames == 7 ? "5.fr%": "8fr"} />
                    <col width={this.props.numberOfGames == 7 ? "5.7fr": "8fr"} />
                    <col width={this.props.numberOfGames == 7 ? "5.7fr": "8fr"} />
                    <col width={this.props.numberOfGames == 7 ? "5.7fr": "8fr"} />
                    {
                        this.props.numberOfGames == 7 ? <col width={"5.7fr"} />:null
                    }
                     {
                        this.props.numberOfGames == 7 ? <col width={"5.7fr"} />:null
                    }
                    <col width={"5fr"} />
                    </colgroup>
                        
                        */
                    }
                    
                   
                    <TableHead >
                        <TableRow style={{ width:"100%", tableLayout:"fixed", }}>
                            {
                                this.props.isTeamTournament ? <TableCell padding="none" style={{ width:"18%"}}>
                                    <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>Team</Typography>
                                </TableCell> : null
                            }
                            <TableCell    style={{width:this.props.isTeamTournament? "25%":"43%"}} padding="none">
                                <Typography  style={{ fontSize: this.state.width * 0.029,width:"100%", }}>Name</Typography>
                            </TableCell>
                            <TableCell    style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>1</Typography>
                            </TableCell>
                            <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>2</Typography>
                            </TableCell>
                            <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>3</Typography>
                            </TableCell>
                            {
                                this.props.numberOfGames >= 5 ? <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>4</Typography>
                            </TableCell> :null
                            }
                            {
                                this.props.numberOfGames >= 5 ? <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>5</Typography>
                            </TableCell> :null
                            }
                            
                            {
                                this.props.numberOfGames == 7 ? <TableCell  style={{width:"5%"}}  >
                                    <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>6</Typography>
                                </TableCell> : null
                            }
                            {
                                this.props.numberOfGames == 7 ? <TableCell  style={{width:"5%"}}   >
                                    <Typography align="center"style={{ fontSize: this.state.width * 0.029,width:"100%" }}>7</Typography>
                                </TableCell> : null
                            }
                            <TableCell  style={{width:"10%"}} padding="none" >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>Total</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>



                    {
                        /* <Grid container  >
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: this.props.isTeamTournament ? "53%" : "33%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>Game</Typography>
                        </Grid>
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>1</Typography>
                        </Grid>
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>2</Typography>
                        </Grid>
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>3</Typography>
                        </Grid>
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>4</Typography>
                        </Grid>
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>5</Typography>
                        </Grid>
                        {
                            this.props.numberOfGames == 7 ? <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>6</Typography>
                            </Grid>
                                : null
                        }
                        {
                            this.props.numberOfGames == 7 ? <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>7</Typography>
                            </Grid> : null
                        }
        
                        <Grid item style={{ borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", borderLeftWidth: "0 1px", width: "8%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderRight: "solid" }} >
                            <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>Total</Typography>
                        </Grid>
        
                    </Grid>*/
                    }
                    
                    <TableBody>
                        
                        <TableRow key={this.props.isTeamTournament+this.props.playerBMatchScore+this.props.playerA+this.props.AScores.toString()}>
                            {
                                this.props.isTeamTournament ?
                                    <TableCell style={{ width:"18%"}} padding="none">
                                        <Typography  style={{  fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{ this.props.editing ? "--" :this.props.TeamAName ? this.props.TeamAName : "TBD"}</Typography>
                                    </TableCell>
                                    : null
                            }


                            <TableCell  style={{width:this.props.isTeamTournament? "25%":"50%"}} padding="none"  >
                                <Typography style={{  fontSize: this.state.width * 0.029,width:"100%", }} noWrap>{this.props.editing ? "--" :this.props.playerA ? this.props.playerA : "TBD"}</Typography>

                            </TableCell>
                            <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}  >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 0 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerAScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerAScore : this.props.AScores[0] != undefined ? this.props.AScores[0] : null
                                }</Typography>
                            </TableCell>
                            <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 1 ? this.props.playerAScore : this.props.AScores[1] != undefined ? this.props.AScores[1] : null
                                }</Typography>
                            </TableCell>
                            <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 2 ? this.props.playerAScore : this.props.AScores[2] != undefined ? this.props.AScores[2] : null
                                }</Typography>
                            </TableCell>
                            
                            {
                                this.props.numberOfGames >= 5 ?<TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 3 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerAScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerAScore : this.props.AScores[3] != undefined ? this.props.AScores[3] : null
                                }</Typography>
                            </TableCell> :null
                            }
                                {
                                    this.props.numberOfGames >= 5 ?<TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 4 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerAScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerAScore : this.props.AScores[4] != undefined ? this.props.AScores[4] : null
                                }</Typography>
                            </TableCell> :null
                                }
                            {
                                this.props.numberOfGames == 7 ? <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                    <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>
                                        {
                                            this.props.playerBMatchScore + this.props.playerAMatchScore == 5 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerAScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerAScore : this.props.AScores[5] != undefined ? this.props.AScores[5] : null
                                        }
                                    </Typography>
                                </TableCell> : null
                            }
                            {
                                this.props.numberOfGames == 7 ? <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                    <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                        this.props.playerBMatchScore + this.props.playerAMatchScore == 6 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerAScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerAScore : this.props.AScores[6] != undefined ? this.props.AScores[6] : null
                                    }</Typography>
                                </TableCell> : null
                            }

                            <TableCell  align="center" style={{width:"10%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{this.props.playerAMatchScore}</Typography>
                            </TableCell>
                        </TableRow>



                        <TableRow>
                            {
                                this.props.isTeamTournament ? <TableCell padding="none" style={{ width:"18%"}}>
                                    <Typography style={{  fontSize: this.state.width * 0.029, width:"100%" }} noWrap>{ this.props.editing ? "--" :this.props.TeamBName ? this.props.TeamBName : "TBD"}</Typography>

                                </TableCell> : null
                            }

                            <TableCell style={{width:this.props.isTeamTournament? "25%":"50%"}} padding="none" >
                                <Typography style={{  fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{ this.props.editing ? "--" :this.props.playerB ? this.props.playerB : "TBD"}</Typography>

                            </TableCell>
                            <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }} >{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 0 ? this.props.playerBScore : this.props.BScores[0] != undefined ? this.props.BScores[0] : null
                                }</Typography>
                            </TableCell>
                            <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 1 ? this.props.playerBScore : this.props.BScores[1] != undefined ? this.props.BScores[1] : null
                                }</Typography>
                            </TableCell>
                            <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 2 ? this.props.playerBScore : this.props.BScores[2] != undefined ? this.props.BScores[2] : null
                                }</Typography>
                            </TableCell>
                            
                            {
                                this.props.numberOfGames >= 5 ? <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 3 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerBScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerBScore : this.props.BScores[3] != undefined ? this.props.BScores[3] : null
                                }</Typography>
                            </TableCell> :null
                            }
                            {
                                this.props.numberOfGames >= 5 ? <TableCell  style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}}>
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 4 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerBScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerBScore  : this.props.BScores[4] != undefined ? this.props.BScores[4] : null
                                }</Typography>
                            </TableCell>:null
                            }
                            {
                                this.props.numberOfGames == 7 ? <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                    <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>
                                        {
                                            this.props.playerBMatchScore + this.props.playerAMatchScore == 5 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerBScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerBScore : this.props.BScores[5] != undefined ? this.props.BScores[5] : null
                                        }
                                    </Typography>
                                </TableCell> : null
                            }
                            {
                                this.props.numberOfGames == 7 ? <TableCell style={{width:this.props.numberOfGames == 7 ? "5%": "8%"}} >
                                    <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                        this.props.playerBMatchScore + this.props.playerAMatchScore == 6 ? this.props.numberOfGames == 5? this.props.playerAMatchScore == 3 || this.props.playerBMatchScore == 3 ? "" : this.props.playerBScore : this.props.playerAMatchScore == 4 || this.props.playerBMatchScore == 4 ? "" : this.props.playerBScore : this.props.BScores[6] != undefined ? this.props.BScores[6] : null
                                    }</Typography>
                                </TableCell> : null
                            }
                            <TableCell style={{width:"10%"}}  align="center"  >
                                <Typography align="center" style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{this.props.playerBMatchScore}</Typography></TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
                {/*
                <Grid container >
                    {this.props.isTeamTournament ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "20%" }} >
                        <Typography style={{ marginLeft: 3, fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{this.props.TeamAName ? this.props.TeamAName : "TBD"}</Typography>
                    </Grid> : null
                    }

                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "33%" }} >
                        <Typography style={{ marginLeft: 3, fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{this.props.playerA ? this.props.playerA : "TBD"}</Typography>
                    </Grid>

                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 0 ? this.props.playerAScore : this.props.AScores[0] != undefined ? this.props.AScores[0] : null
                        }</Typography>
                    </Grid>
                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 1 ? this.props.playerAScore : this.props.AScores[1] != undefined ? this.props.AScores[1] : null
                        }</Typography>
                    </Grid>
                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 2 ? this.props.playerAScore : this.props.AScores[2] != undefined ? this.props.AScores[2] : null
                        }</Typography>
                    </Grid>
                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 3 ? this.props.playerAScore : this.props.AScores[3] != undefined ? this.props.AScores[3] : null
                        }</Typography>
                    </Grid>
                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 4 ? this.props.playerAScore : this.props.AScores[4] != undefined ? this.props.AScores[4] : null
                        }</Typography>
                    </Grid>
                    {
                        this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>
                                {
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 5 ? this.props.playerAScore : this.props.AScores[5] != undefined ? this.props.AScores[5] : null
                                }
                            </Typography>
                        </Grid> : null
                    }
                    {
                        this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                            <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                this.props.playerBMatchScore + this.props.playerAMatchScore == 6 ? this.props.playerAScore : this.props.AScores[6] != undefined ? this.props.AScores[6] : null
                            }</Typography>
                        </Grid> : null
                    }



                    <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "8%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderRight: "solid" }} >
                        <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{this.props.playerAMatchScore}</Typography>
                    </Grid>


                </Grid> */}
            

            {
                /*<Grid container >
                            {this.props.isTeamTournament ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "20%" }} >
                                <Typography style={{ marginLeft: 3, fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{this.props.TeamAName ? this.props.TeamAName : "TBD"}</Typography>
                            </Grid> : null
                            }
            
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "33%" }} >
                                <Typography style={{ marginLeft: 3, fontSize: this.state.width * 0.029,width:"100%" }} noWrap>{this.props.playerA ? this.props.playerA : "TBD"}</Typography>
                            </Grid>
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                                <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 0 ? this.props.playerAScore : this.props.AScores[0] != undefined ? this.props.AScores[0] : null
                                }</Typography>
                            </Grid>
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                                <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 1 ? this.props.playerAScore : this.props.AScores[1] != undefined ? this.props.AScores[1] : null
                                }</Typography>
                            </Grid>
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                                <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 2 ? this.props.playerAScore : this.props.AScores[2] != undefined ? this.props.AScores[2] : null
                                }</Typography>
                            </Grid>
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                                <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 3 ? this.props.playerAScore : this.props.AScores[3] != undefined ? this.props.AScores[3] : null
                                }</Typography>
                            </Grid>
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", }} >
                                <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                    this.props.playerBMatchScore + this.props.playerAMatchScore == 4 ? this.props.playerAScore : this.props.AScores[4] != undefined ? this.props.AScores[4] : null
                                }</Typography>
                            </Grid>
                            {
                                this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                                    <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>
                                        {
                                            this.props.playerBMatchScore + this.props.playerAMatchScore == 5 ? this.props.playerAScore : this.props.AScores[5] != undefined ? this.props.AScores[5] : null
                                        }
                                    </Typography>
                                </Grid> : null
                            }
                            {
                                this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle" }} >
                                    <Typography style={{ fontSize: this.state.width * 0.029,width:"100%" }}>{
                                        this.props.playerBMatchScore + this.props.playerAMatchScore == 6 ? this.props.playerAScore : this.props.AScores[6] != undefined ? this.props.AScores[6] : null
                                    }</Typography>
                                </Grid> : null
                            }
            
            
            
                            <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "8%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderRight: "solid" }} >
                                <Typography style={{ fontSize: this.state.width * 0.029 }}>{this.props.playerAMatchScore}</Typography>
                            </Grid>
            
            
                        </Grid>
             */
            }
            {/* 
            <Grid container >
                {
                    this.props.isTeamTournament ? <Grid
                        item
                        style={{
                            borderLeftWidth: "0 1px",
                            borderColor: "black",
                            borderTop: "solid",
                            borderLeft: "solid",
                            borderBottom: "solid",
                            borderBottomWidth: "0 1px",
                            borderTopWidth: "0 1px",
                            width: "20%"
                        }} >
                        <Typography
                            style={{
                                marginLeft: 3,
                                fontSize: this.state.width * 0.029
                            }}
                            noWrap
                        >
                            {this.props.TeamBName ? this.props.TeamBName : "TBD"}
                        </Typography>
                    </Grid> : null
                }

                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "33%", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ marginLeft: 3, fontSize: this.state.width * 0.029 }} noWrap>{this.props.playerB ? this.props.playerB : "TBD"}</Typography>
                </Grid>
                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>{
                        this.props.playerBMatchScore + this.props.playerAMatchScore == 0 ? this.props.playerBScore : this.props.BScores[0] != undefined ? this.props.BScores[0] : null
                    }</Typography>
                </Grid>
                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>
                        {this.props.playerBMatchScore + this.props.playerAMatchScore == 1 ? this.props.playerBScore : this.props.BScores[1] != undefined ? this.props.BScores[1] : null}
                    </Typography>
                </Grid>
                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>{
                        this.props.playerBMatchScore + this.props.playerAMatchScore == 2 ? this.props.playerBScore : this.props.BScores[2] != undefined ? this.props.BScores[2] : null
                    }</Typography>
                </Grid>
                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>{
                        this.props.playerBMatchScore + this.props.playerAMatchScore == 3 ? this.props.playerBScore : this.props.BScores[3] != undefined ? this.props.BScores[3] : null
                    }</Typography>
                </Grid>
                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>{
                        this.props.playerBMatchScore + this.props.playerAMatchScore == 4 ? this.props.playerBScore : this.props.BScores[4] != undefined ? this.props.BScores[4] : null
                    }</Typography>
                </Grid>
                {
                    this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", }} >
                        <Typography style={{ fontSize: this.state.width * 0.029 }}>{this.props.playerBMatchScore + this.props.playerAMatchScore == 5 ? this.props.playerBScore : this.props.BScores[5] != undefined ? this.props.BScores[5] : null}</Typography>
                    </Grid> : null
                }

                {
                    this.props.numberOfGames == 7 ? <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "5%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px" }} >
                        <Typography style={{ fontSize: this.state.width * 0.029, }}>{
                            this.props.playerBMatchScore + this.props.playerAMatchScore == 6 ? this.props.playerBScore : this.props.BScores[6] != undefined ? this.props.BScores[6] : null
                        }</Typography>
                    </Grid> : null
                }


                <Grid item style={{ borderLeftWidth: "0 1px", borderColor: "black", borderTop: "solid", borderLeft: "solid", borderTopWidth: "0 1px", width: "8%", display: "inline-flex", justifyContent: "center", alignItems: "center", verticalAlign: "middle", borderBottom: "solid", borderBottomWidth: "0 1px", borderRight: "solid" }} >
                    <Typography style={{ fontSize: this.state.width * 0.029 }}>{this.props.playerBMatchScore}</Typography>
                </Grid>

            </Grid>
            
            */
            }
            {
                this.state.hasGameStats? <LiveStats {...this.state.gameStats} /> : null
            }
            

           </Paper> 
        )
    }
}

